import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Styles from './styles.module.scss'
import { selectUnreadMessagesCount } from 'defaultLayout/slice/selectors'
import { Badge } from '@mui/material'

export const TherapistNav = () => {
  const unreadMessagesCount = useSelector(selectUnreadMessagesCount)

  return (
    <div className={Styles.tabs}>
      <NavLink to="/profile/therapist/applications" className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
        <span>{'Applications'}</span>
      </NavLink>
      <NavLink to="/profile/therapist/patients" className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
        <span>{'My patients'}</span>
      </NavLink>
      <Badge
        sx={{
          maxWidth: 'fit-content',
          alignSelf: ['center', 'center', 'center', 'flex-start'],
        }}
        badgeContent={unreadMessagesCount ?? 0}
        color="secondary"
      >
        <NavLink
          style={{ marginRight: 0 }}
          to={`/profile/therapist/chat`}
          className={({ isActive }) => (isActive ? `${Styles.active} ${Styles.chat}` : `${Styles.tab} ${Styles.chat}`)}
        >
          <span>{'Chats'}</span>
        </NavLink>
      </Badge>
    </div>
  )
}
