const therapistRoot = 'therapists'
const userRoot = 'users'
/**
 * Initializing API routes, one centralized place for all API routes
 * Easier to maintain and update
 * */
export const apiRoutes = {
  courses: {
    getCourses: 'course/all/',
    getAllPurchasedCourses: 'course/all-purchased/',
    userPurchasedCourses: 'course/user-purchased-courses/',
    purchase: 'course/purchase/',
    streamVideo: 'course/stream/',
    getCourseDetail: 'course/detail/',
    create_course_webhookUrl: 'api/course/webhook/create-course-order/',
    updateVideoTime: 'course/update-video-time/',
    getSignedUrl: 'course/get-signed-url/',
    answerMcq: 'course/update-mcq-answer/',
    completeCourse: 'course/complete-lesson/',
    getDocuments: 'course/get-documents/',
    rateCourse: 'course/rate-course/',
    getCourseRatings: 'course/ratings/',
    getUserRating: 'course/user-rating/',
  },
  chats: {
    getParentChannels: 'chats/profiles/',
    getOtherUsersChannels: 'chats/',
  },
  promoCode: {
    linkExistingUser: 'users/link-promo-code/',
  },
  joinReason: 'users/save-join-reason/',
  parent: {
    addChild: 'parents/add-child-profile/',
    afterDiscovery: 'parents/send-email-after-discovery-call/',
  },
  therapist: {
    create: `${therapistRoot}/create/`,
    get: `${therapistRoot}/`,
    getScreenings: `${therapistRoot}/screenings/`,
  },
  user: {
    checkEmailForAuthenticated: `${userRoot}/check-mail-authenticated/`,
    signUp: `${userRoot}/signup/`,
  },
  getSessionLengthPrice: 'calcom/get_variable_sessions/',
  submitFeedback: 'calcom/sessions/feedback/',
  landing: {
    failConsultation: 'landing/fail-consultation/',
  },
  createGoal: 'treatment_plan/create/goal/',
  updateGoal: 'treatment_plan/update/goal/',
  updateNoteVisibility: 'treatment_plan/update/note/visibility/',
  getTreatmentPlans: 'treatment_plan',
  getParentTasks: 'parent_task/',
  createProgress: 'treatment_plan/create/progress/',
  getObjectives: 'treatment_plan/get/objectives',
  getProgress: 'treatment_plan/get/progress',
  updateObjectiveStatus: 'treatment_plan/update/objective/status/',
  getAllProfiles: 'parents/profiles/',
  parentTask: {
    create: 'parent_task/create/',
    update: 'parent_task/update/',
    comment: 'parent_task/comment/',
    getOne: 'parent_task/get',
    delete: 'parent_task/delete',
  },
  adminAnalytics: {
    conversions: 'admin_analytics/conversions',
    revenue: 'admin_analytics/revenue',
    activeUsers: 'admin_analytics/active-users',
    therapistPerformance: 'admin_analytics/therapist-performance',
    courses: {
      allCourses: 'admin_analytics/courses/',
      totals: 'admin_analytics/courses-total/',
    },
  },
  web_push_subscription: {
    get: 'push_notification/subscriptions/',
    unsubscribe: 'push_notification/unsubscribe/',
    removeSubscription: 'push_notification/remove-subscription/',
    removeChannel: 'push_notification/remove-channel/',
    addChannel: 'push_notification/add-channel/',
  },
  getNotificationChannels: 'users/notification-channels/',
  notifications: {
    opened: 'notifications/opened/',
  },
}
