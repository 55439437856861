import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import { useDispatch } from 'react-redux'
import { Placeholder } from 'components/ChatSystem/Common/MessagesHistory/Placeholder'
import { MessagesHistory } from 'components/ChatSystem/Common/MessagesHistory'
import { chatActions } from 'actions'
import { CenterColumn, CenterRow, Container, Flex, Text } from 'components/Core'
import { ChatRoom, useChat } from './useChat'
import { theme } from 'utils/theme'
import { InputAdornment, Tab, Tabs, TextField, useMediaQuery } from '@mui/material'
import { userTypes } from 'constants/index'
import { Search } from '@mui/icons-material'
import { TFN, User } from 'utils/types/types'
import { CustomAccordion } from 'components/shared/CustomAccordion'
import { ProfileSelector } from 'pages/ShardPage/ParentTask/ProfileSelector'
import { useViewMessagesMutation } from './service'
import { shutdown } from '@intercom/messenger-js-sdk'
import { useIntercom } from 'hooks/useIntercom'

export const ChatPage = () => {
  const [selectedChat, setSelectedChat] = useState<ChatRoom | null>(null)
  const [showBanner, setShowBanner] = useState(false)

  const { refetchChats, setSelectedProfileId, t, user, tab, setTab, filteredChannels, searchTerm, setSearchTerm } = useChat()
  const [viewMessages] = useViewMessagesMutation()
  const handleViewLastMessages = (un_viewed_messages: Array<number>) => {
    if (selectedChat && un_viewed_messages.length > 0) {
      const data = {
        chatId: selectedChat.chat_id,
        un_viewed_messages,
        is_group_chat: selectedChat.participants.length > 1,
      }
      viewMessages(data).finally(() => {
        setTimeout(() => {
          refetchChats()
        }, 1000)
      })
    }
  }

  // const handleChannelsListUpload = useCallback(() => {
  //   if (user) {
  //     const data = {
  //       id: '',
  //     }

  //     if (user?.user_type === 'parent') {
  //       data.id = String(selectedProfileId ?? profileId)
  //       dispatch(chatActions.getAllChannelsClient(data) as any)
  //     } else {
  //       dispatch(chatActions.getAllChannels(data) as any)
  //     }
  //   }
  // }, [user.user_type, selectedProfileId])

  const handleGoBackToAllChannels = () => {
    setSelectedChat(null)
  }

  // useEffect(() => {
  //   handleChannelsListUpload()
  // }, [handleChannelsListUpload])

  const belowLg = useMediaQuery('(max-width: 992px)')
  const { bootIntercom } = useIntercom(false)
  useEffect(() => {
    shutdown()

    return () => {
      bootIntercom()
    }
  }, [])

  return (
    <Flex bg="white" height="100%" maxHeight="100%">
      {!belowLg && (
        <ChatList
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          t={t}
          tab={tab}
          setTab={setTab}
          filteredChannels={filteredChannels}
          selectedChat={selectedChat}
          setSelectedChat={setSelectedChat}
          onSelect={(value) => {
            setShowBanner(true)
            setSelectedChat(value)
          }}
          setSelectedProfileId={setSelectedProfileId}
          user={user}
        />
      )}

      <CenterColumn minHeight="100%" flexGrow={1}>
        {selectedChat ? (
          // @ts-ignore
          <MessagesHistory
            currentUserId={user?.id}
            currentUserEmail={user?.email}
            roomId={selectedChat.room_id || localStorage.getItem('room_id')}
            chatId={selectedChat.chat_id}
            selectedChat={selectedChat}
            goBackToChatList={handleGoBackToAllChannels}
            onNewMessageSend={refetchChats}
            onViewLastMessages={handleViewLastMessages}
            isGroupChat={selectedChat.participants.length > 1}
            grouped={selectedChat.participants.length > 1}
            showBanner={showBanner}
            setShowBanner={setShowBanner}
            // onChatDetailsOpenning={handleOpenConatctDetails}
          />
        ) : belowLg ? (
          <ChatList
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            t={t}
            tab={tab}
            setTab={setTab}
            filteredChannels={filteredChannels}
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
            setSelectedProfileId={setSelectedProfileId}
            user={user}
            onSelect={(value) => {
              setShowBanner(true)
              setSelectedChat(value)
            }}
          />
        ) : (
          <Container height="100vh">
            <Placeholder text={t('chat:selectChat')} />
          </Container>
        )}
      </CenterColumn>
    </Flex>
  )
}

type ChatListProps = {
  selectedChat: ChatRoom | null
  filteredChannels: Array<ChatRoom> | undefined
  tab: string
  searchTerm: string
  user: Partial<User>
  setSearchTerm: Dispatch<SetStateAction<string>>
  setSelectedChat: Dispatch<SetStateAction<ChatRoom | null>>
  setTab: Dispatch<SetStateAction<string>>
  t: TFN
  setSelectedProfileId: (value: number | string | undefined) => void

  onSelect: (value: ChatRoom | null) => void
}
const ChatList = ({ filteredChannels, user, selectedChat, tab, searchTerm, setSearchTerm, setSelectedChat, setTab, t, setSelectedProfileId, onSelect }: ChatListProps) => {
  const getRoomDisplayName = (room: ChatRoom, grouped?: boolean) => {
    if (room.room_name) return room.room_name
    return room.participants.map((p) => (!grouped ? p.user_name : p.profile_name ?? p.user_name)).join(', ')
  }

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return ''
    return format(new Date(dateString), 'MMM d, yyyy h:mm a')
  }
  const belowMd = useMediaQuery('(max-width: 768px)')
  const isGroupChat = useCallback((room: ChatRoom) => Number(room?.participants.length) > 1, [selectedChat])
  return (
    <Flex minWidth={[350, 350, 400]} overflowY="scroll" height="100%" flexDirection="column" backgroundColor="white" gap={'8px'} p={8}>
      <Tabs
        onChange={(_e, value) => {
          setTab(value)
        }}
        value={tab}
        variant="scrollable"
        sx={{ width: belowMd ? '350px' : '400px', mb: '8px' }}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab label={t('chat:allChats')} value={'1'} />
        <Tab label={t('chat:group')} value={'group'} />
        {user.user_type !== 'parent' && <Tab label={t('chat:parents')} value={JSON.stringify([userTypes.parent, 'child'])} />}
        <Tab label={t('chat:advisors')} value={JSON.stringify([userTypes.advisor])} />
        <Tab label={t('chat:therapists')} value={JSON.stringify([userTypes.therapist])} />
        <Tab label={t('chat:admins')} value={JSON.stringify([userTypes.admin])} />
      </Tabs>
      {user.user_type === userTypes.parent && (
        <CenterRow width={'100%'}>
          <ProfileSelector
            width="180px"
            onChange={(value) => {
              setSelectedProfileId(value.id)
              setSelectedChat(null)
            }}
          />
        </CenterRow>
      )}
      <TextField
        size="small"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder={t('chat:search')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      {filteredChannels?.map((chat: ChatRoom) =>
        chat.grouped ? (
          <CustomAccordion defaultExpanded title={chat.parent_name as string} key={chat.chat_id} titleAlignment="left">
            {chat.rooms?.map((room) => (
              <ChatItem
                position="relative"
                width="100%"
                flexDirection={['column']}
                gap={8}
                key={room?.chat_id}
                onClick={() => onSelect(room)}
                selected={selectedChat?.chat_id === room?.chat_id}
              >
                <CenterRow flexWrap={['wrap']} width="100%" justifyContent="space-between">
                  {/* <AvatarGroup>
                    {room?.participants.map((participant) => (
                      <Avatar sizes="small" key={participant.user_id} src={participant.profile_pic ?? 'https://placehold.co/400'} alt={participant.profile_name} />
                    ))}
                  </AvatarGroup> */}
                  <ChatInfo>
                    <ChatName>{getRoomDisplayName(room, true)}</ChatName>
                    <Text maxWidth={300} variant="light" truncate>
                      {room?.last_message?.text}
                    </Text>
                  </ChatInfo>
                </CenterRow>
                <ChatMeta>
                  <TimeStamp>{formatDate(chat?.last_message?.created_at)}</TimeStamp>
                  {room?.unread_messages > 0 && (
                    <Text top={8} right={8} position="absolute" size={25} fontSize={12} variant="numberHolder">
                      {room?.unread_messages}
                    </Text>
                  )}
                </ChatMeta>
              </ChatItem>
            ))}
          </CustomAccordion>
        ) : (
          <ChatItem
            position="relative"
            width="100%"
            flexDirection={['column']}
            gap={8}
            key={chat.chat_id}
            onClick={() => onSelect(chat)}
            selected={selectedChat?.chat_id === chat.chat_id}
          >
            <CenterRow flexWrap={['wrap']} width="100%" justifyContent="space-between">
              <AvatarGroup>
                {chat.participants.map((participant) => (
                  <Avatar sizes="small" key={participant.user_id} src={participant.profile_pic ?? 'https://placehold.co/400'} alt={getRoomDisplayName(chat)} />
                ))}
              </AvatarGroup>
              <ChatInfo>
                <ChatName>{getRoomDisplayName(chat)}</ChatName>
                <Text maxWidth={300} variant="light" truncate>
                  {chat?.last_message?.text}
                </Text>
              </ChatInfo>
            </CenterRow>
            <ChatMeta>
              <TimeStamp>{formatDate(chat?.last_message?.created_at)}</TimeStamp>
              {chat?.unread_messages > 0 && (
                <Text top={8} right={8} position="absolute" size={25} fontSize={12} variant="numberHolder">
                  {chat?.unread_messages}
                </Text>
              )}
            </ChatMeta>
          </ChatItem>
        ),
      )}
    </Flex>
  )
}

const ChatItem = styled(Flex)<{ selected?: boolean }>`
  padding: 15px;
  cursor: pointer;
  min-width: 300px;
  height: max-content;
  background: ${(props) => (props.selected ? theme.colors.primary['100'] : 'white')};
  // box-shadow: ${theme.shadows.normal};

  &:hover {
    background: ${theme.colors.primary[100]};
  }
`

const AvatarGroup = styled.div`
  display: flex;
  margin-right: 12px;
`

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: -10px;
  border: 2px solid white;
`

const ChatInfo = styled.div`
  flex: 1;
`

const ChatName = styled.div`
  font-weight: 600;
  margin-bottom: 4px;
`

const LastMessageText = styled.div`
  color: #666;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ChatMeta = styled.div`
  display: flex;
  flex-direction: column;
`

const TimeStamp = styled.div`
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
`

const UnreadBadge = styled(Container)`
  background: ${theme.colors.typePurpure};
  color: white;
  padding: 8px;
  border-radius: 50px;
  font-size: 12px;
  width: 25px;
  height: 25px;
`

const ChatHeader = styled.div`
  padding: 15px;
  background: white;
  border-bottom: 1px solid #e0e0e0;
`

const HeaderContent = styled.div``

const HeaderTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
`

const ParticipantsList = styled.div`
  font-size: 14px;
  color: #666;
  margin-top: 4px;
`

const MessageArea = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
`

const MessageInput = styled.div`
  padding: 15px;
  background: white;
  border-top: 1px solid #e0e0e0;
  display: flex;
  gap: 10px;
`

const Input = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  outline: none;

  &:focus {
    border-color: #1a73e8;
  }
`

const SendButton = styled.button`
  padding: 10px 20px;
  background: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #1557b0;
  }
`

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #666;
`
