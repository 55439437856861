import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'
import type { User } from 'utils/types/types'
type QueryArgs = {
  payload: {
    child_info: Object
    questionnaire: Object
    family_dynamics: Object
    parental_wellbeing: Object
  }
}
const defaultLayoutService = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    createNewProfile: build.mutation<{ id: number }, QueryArgs>({
      query: (data) => ({
        url: apiRoutes.parent.addChild,
        method: 'POST',
        data: data.payload,
        // headers: {
        //   Authorization: `Token ${data.token}`,
        // },
      }),
    }),
    getUser: build.query<User, number | undefined>({
      query: (userId) => ({
        url: `users/${userId}/`,
        method: 'GET',
      }),
      providesTags: ['get-user'],
      keepUnusedDataFor: 3600, // 1hr
    }),
    getUserByUid: build.mutation<User, string | undefined>({
      query: (firebaseUid) => ({
        url: `users/${firebaseUid}/`,
        method: 'GET',
      }),
    }),
    openNotification: build.mutation<string, string | undefined>({
      query: (notificationId) => ({
        url: apiRoutes.notifications.opened,
        method: 'PUT',
        data: {
          id: notificationId,
        },
      }),
    }),
    signUpUser: build.mutation<{ id: number }, { full_name: string; email: string; password: string }>({
      query: (data) => ({
        url: apiRoutes.user.signUp,
        method: 'POST',
        data,
      }),
    }),
    linkExistingUserWithPromoCode: build.mutation<string, { promo_code: string }>({
      query: (data) => ({
        url: apiRoutes.promoCode.linkExistingUser,
        method: 'POST',
        data,
      }),
    }),
    saveUserJoinReason: build.mutation<string, { join_reason: string }>({
      query: (data) => ({
        url: apiRoutes.joinReason,
        method: 'POST',
        data,
      }),
    }),
  }),
})

export const {
  useGetUserQuery,
  useOpenNotificationMutation,
  useGetUserByUidMutation,
  useCreateNewProfileMutation,
  useSignUpUserMutation,
  useLinkExistingUserWithPromoCodeMutation,
  useSaveUserJoinReasonMutation,
} = defaultLayoutService
