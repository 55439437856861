import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'

type ResultType = { data: Array<{ duration: number; price: number }> }
type QueryArgs = { therapist_id: any }

export const checkEmailExistenceServiceForAuthenticatedUser = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    getSessionLengthPrice: build.mutation<ResultType, QueryArgs>({
      query: ({ therapist_id }) => {
        return {
          url: apiRoutes.getSessionLengthPrice,
          method: 'POST',
          data: {
            therapist_id,
            profile_id: Number(localStorage.getItem('lastSelectedProfile')) || 0,
          },
        }
      },
    }),
  }),
})

export const { useGetSessionLengthPriceMutation } = checkEmailExistenceServiceForAuthenticatedUser
