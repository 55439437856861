// Styles
import Styles from './Styles.module.scss'
// Utils
import { getInitials, returnCountry } from '../../../utils'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { CloseIcon, EyeVisible } from '../../../theme/assets/icons'
import { Divider, IconButton, Modal } from '@mui/material'
import { useState } from 'react'
import { Box, Flex, Container, Text, CenterRow, CenterColumn } from 'components/Core'
import { ProfileName } from './ProfileName'
const statusColor = {
  waiting: '#FFC107',
  accepted: '#4CAF50',
  declined: '#F44336',
}

const getColorForDiscoveryCallStatus = (value) => {
  switch (value) {
    case 'Booked':
      return statusColor.accepted
    case 'Canceled':
      return statusColor.declined
    default:
      return statusColor.waiting
  }
}
export const GeneralProfileInformation = ({ profile, client, advisor }) => {
  const [reason, setReason] = useState('')
  const { open, onClose, onOpen } = useDisclosure()

  return (
    <Box bg="white" p={20} gap={16} height={'100%'}>
      <ProfileName name={profile?.full_name} />
      <Flex width="100%" gap="25px" flexDirection="column" pb={['24px']} borderBottom="1px solid" borderBottomColor="purple.200">
        <Flex gap="10px" flexDirection={['column', 'column', 'row']} width="100%" justifyContent={['space-between']}>
          <CenterRow gap="16px" justifyContent={['space-between']}>
            <Text variant="body6">Age:</Text>
            <Text variant="body5">{profile?.age}</Text>
          </CenterRow>
          <CenterRow gap="16px" justifyContent={['space-between']}>
            <Text variant="body6">Birthday:</Text>
            <Text variant="body5">{profile?.birth_date}</Text>
          </CenterRow>
        </Flex>
        <Flex flexWrap="wrap" gap={['10px']} flexDirection={['column', 'column', 'row']} justifyContent={['space-between']}>
          <CenterRow justifyContent={['space-between']} gap="16px">
            <Text variant="body6">Languages:</Text>
            <Text variant="body5">{profile?.languages?.join(', ')}</Text>
          </CenterRow>
          <CenterRow flexWrap={['wrap']} gap={['5px', '5px', '16px']} justifyContent={['space-between']}>
            <Text variant="body6">Problems:</Text>
            <Text variant="body5">{profile?.concerns?.join(', ')}</Text>
          </CenterRow>
        </Flex>
        <Flex gap="10px" flexDirection={['column', 'column', 'row']} width="100%" justifyContent={['space-between']}>
          <CenterRow flexWrap={['wrap']} gap={['5px', '5px', '16px']} justifyContent={['space-between']}>
            <Text variant="body6">Usual behavior:</Text>
            <Text variant="body5">{profile?.behavior?.join(', ')}</Text>
          </CenterRow>
        </Flex>
      </Flex>
      <Box my={[25]}>
        <Flex gap="10px" flexDirection={['column', 'column', 'row']} width="100%" justifyContent={['space-between']}>
          <CenterRow gap="16px" justifyContent={['space-between']}>
            <Text variant="body6">Client name:</Text>
            <Text variant="body5">{client?.full_name}</Text>
          </CenterRow>
          <CenterRow gap="16px" justifyContent={['space-between']}>
            <Text variant="body6">Country:</Text>
            <Text variant="body5">{returnCountry(client?.country)}</Text>
          </CenterRow>
        </Flex>
      </Box>
      {profile?.assignedTherapists && (
        <Flex flexDirection={['column']}>
          <h6>Assigned Therapists Status</h6>
          <div style={{ gap: '10px', marginTop: 10 }} className={Styles.main_container__client_info}>
            <Divider />
            {profile?.assignedTherapists?.map((therapist) => (
              <CenterColumn gap={10} key={therapist?.id}>
                <div className={Styles.main_container__client_info__container} key={therapist?.id}>
                  <div>
                    <span>{therapist?.user?.full_name}</span>
                  </div>
                  <div>
                    <p>Therapist:</p>
                    <span style={{ textTransform: 'uppercase', color: statusColor[therapist?.approval_status?.therapist_status] }}>
                      {therapist?.approval_status?.therapist_status}
                    </span>
                    <ShowReason
                      onClick={() => {
                        setReason(therapist?.approval_status?.decline_reason)
                        onOpen()
                      }}
                      onOpen={onOpen}
                      status={therapist?.approval_status?.therapist_status}
                    />
                  </div>
                  <div>
                    <p>Parent:</p>
                    <span style={{ textTransform: 'uppercase', color: statusColor[therapist?.approval_status?.parent_status] }}>{therapist?.approval_status?.parent_status}</span>
                    <ShowReason
                      onClick={() => {
                        setReason(therapist?.approval_status?.decline_reason)
                        onOpen()
                      }}
                      onOpen={onOpen}
                      status={therapist?.approval_status?.parent_status}
                    />
                  </div>
                  <Flex>
                    <Text>Discovery:</Text>
                    <span style={{ textTransform: 'uppercase', color: getColorForDiscoveryCallStatus(therapist?.approval_status?.discovery_call_booked) }}>
                      {therapist?.approval_status?.discovery_call_booked}
                    </span>
                  </Flex>
                </div>
                <Divider />
              </CenterColumn>
            ))}
          </div>
        </Flex>
      )}
      {advisor && (
        <Flex mt={[2]} alignItems={['center']} gap="16px">
          <div className={Styles.main_container__advisor_info__initials}>{getInitials(advisor?.full_name)}</div>
          <div>
            <p>{advisor?.full_name}</p>
            <Text mt={[1]} variant="body6">
              Advisor
            </Text>
          </div>
        </Flex>
      )}
      <PopupDetail onClose={onClose} open={open} reason={reason} />
    </Box>
  )
}

const PopupDetail = ({ reason, open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box bgcolor="white" p={3} width={['90%', '70%', '50%', '30%']} borderRadius={3}>
        <Box display="flex" mb={1} justifyContent="space-between" alignItems="center">
          <h6>Reason</h6>
          <IconButton>
            <CloseIcon className={Styles.header__icon} onClick={onClose} />
          </IconButton>
        </Box>
        {reason}
      </Box>
    </Modal>
  )
}

const ShowReason = ({ status, onClick }) => {
  return status === 'declined' ? (
    <IconButton onClick={onClick}>
      <EyeVisible />
    </IconButton>
  ) : null
}
