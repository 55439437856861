import { initializeApp } from 'firebase/app'
import { format } from 'date-fns'
import { confirmPasswordReset, getAuth, signInWithEmailAndPassword, verifyPasswordResetCode } from 'firebase/auth'
import { notify } from 'helpers'
import { Dispatch, SetStateAction } from 'react'
import axios from 'axios'

type Options = {
  date: string | undefined
  formatType?: string
}
export const dateFormatter = ({ date, formatType = 'PPpp' }: Options) => {
  try {
    if (!date) return null
    return format(new Date(date), formatType)
  } catch (error) {
    console.log(error)
    return null
  }
}

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

export const firebaseApp = initializeApp(firebaseConfig)

export const authInstance = getAuth(firebaseApp)

type RestProps = {
  actionCode: string
  continueUrl: string
  newPassword: string
  lang?: string
  onSuccess: () => void
  setLoading: Dispatch<SetStateAction<boolean>>
}
export function handleResetPassword({ actionCode, newPassword, onSuccess, setLoading }: RestProps) {
  // Localize the UI to the selected language as determined by the lang
  // parameter.

  // Verify the password reset code is valid.
  verifyPasswordResetCode(authInstance, actionCode)
    .then((email) => {
      const accountEmail = email

      // TODO: Show the reset screen with the user's email and ask the user for
      // the new password.

      // Save the new password.
      confirmPasswordReset(authInstance, actionCode, newPassword)
        .then(async (resp) => {
          // Password reset has been confirmed and new password updated.
          // TODO: Display a link back to the app, or sign-in the user directly
          // if the page belongs to the same domain as the app:
          await signInWithEmailAndPassword(authInstance, accountEmail, newPassword).then(() => {
            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
            onSuccess?.()
          })
        })
        .catch((error) => {
          // Error occurred during confirmation. The code might have expired or the
          // password is too weak.
          console.log(error)
          notify(error.message)
        })
        .finally(() => {
          setLoading(false)
        })
    })
    .catch((error) => {
      // Invalid or expired action code. Ask user to try to reset the password again.
      notify('Session Invalid or expired. Please try to reset the password again.')
    })
    .finally(() => {
      setLoading(false)
    })
}
type CurrencyFormatterProps = {
  amount: number | undefined
  currency?: string
}
export const currencyFormatter = ({ amount = 0, currency = 'USD' }: CurrencyFormatterProps) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  })
  return formatter.format(amount)
}

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY as string

export const encryptAnswer = (text: string) => {
  let encrypted = ''
  for (let i = 0; i < text.length; i++) {
    const charCode = text.charCodeAt(i) ^ ENCRYPTION_KEY.charCodeAt(i % ENCRYPTION_KEY?.length)
    encrypted += String.fromCharCode(charCode)
  }
  return btoa(encrypted)
}

export const decryptAnswer = (encrypted: string) => {
  const decoded = atob(encrypted)
  let decrypted = ''
  for (let i = 0; i < decoded.length; i++) {
    const charCode = decoded.charCodeAt(i) ^ ENCRYPTION_KEY.charCodeAt(i % ENCRYPTION_KEY.length)
    decrypted += String.fromCharCode(charCode)
  }
  return decrypted
}

export const getVideoType = (url: string | undefined | null) => {
  if (!url || typeof url !== 'string') return 'video/mp4'

  const extension = url.split('.').pop()?.split('?')[0]?.toLowerCase()

  const videoTypes = {
    mp4: 'video/mp4',
    webm: 'video/webm',
    ogg: 'video/ogg',
    mov: 'video/quicktime',
    m4v: 'video/mp4',
  }

  return videoTypes[extension as keyof typeof videoTypes] || 'video/mp4'
}

export const ceilingPrice = (price: number): number => {
  return Math.ceil(price * 10) / 10
}

export const calculateDiscountedPrice = (price: number, discount: number) => {
  return price - (price * discount) / 100
}
