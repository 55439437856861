import { useAuth } from 'hooks/useAuth'
import { useContentBundle } from 'hooks/useContentBundle'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import en from './locale/en.json'
import ar from './locale/ar.json'
import { userTypes } from 'constants/index'
import { useGetChannelsQuery } from './service'
import { useDefaultLayoutSlice } from 'defaultLayout/slice'
import { selectChatSelectedProfile } from 'defaultLayout/slice/selectors'

interface Participant {
  user_id: number
  user_name: string
  user_type: string
  profile_name: string
  role: string
  profile_pic: string
}

interface LastMessage {
  text: string
  author: number
  created_at: string
}

export interface ChatRoom {
  chat_id: number
  room_id: string
  room_name: string | null
  participants: Participant[]
  unread_messages: number
  last_message: LastMessage
  grouped?: boolean
  rooms?: ChatRoom[]
  parent_name?: string
}
export const useChat = () => {
  const lastSelectedProfile = localStorage.getItem('lastSelectedProfile')
  const [tab, setTab] = useState<string>('1')
  const { actions, dispatch } = useDefaultLayoutSlice()
  const selectedProfileId = useSelector(selectChatSelectedProfile)
  const setSelectedProfileId = useCallback((value: number | string | undefined) => {
    dispatch(actions.setChatSelectedProfile(value))
  }, [])
  const [searchTerm, setSearchTerm] = useState('')
  const { user } = useAuth()
  const { data: channels, refetch: refetchChats } = useGetChannelsQuery(
    {
      profile_id: String(selectedProfileId ?? lastSelectedProfile),
      userType: user.user_type,
    },
    {
      skip: !user.user_type,
      refetchOnMountOrArgChange: true,
    },
  )

  const filterBySearchTerm = (channels: ChatRoom[], searchTerm: string) => {
    return channels.filter((channel) => {
      const roomNameMatch = channel.room_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      const participantProfileNameMatch = channel.participants.some((participant) => participant?.profile_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
      const participantUserNameMatch = channel.participants.some((participant) => participant?.user_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
      const roleMatch = channel.participants.some((participant) => participant?.role?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
      return roomNameMatch || participantProfileNameMatch || participantUserNameMatch || roleMatch
    })
  }

  const filteredChannels = useMemo(() => {
    let filtered: Array<ChatRoom> | undefined = []
    if (tab === '1') {
      if (searchTerm) {
        filtered = filterBySearchTerm(channels ?? [], searchTerm)
      } else {
        filtered = channels
      }
    } else if (tab === 'group') {
      if (searchTerm) {
        filtered = filterBySearchTerm(channels ?? [], searchTerm)
      } else {
        filtered = channels?.filter((channel) => channel.participants?.length > 2 || Boolean(channel.room_name))
      }
    } else {
      filtered = channels?.filter((channel) => channel.participants.some((participant) => JSON.parse(tab as string).includes(participant.role)) && !Boolean(channel.room_name))
      if (searchTerm) {
        filtered = filterBySearchTerm(filtered ?? [], searchTerm)
      }
    }

    const grouped = filtered?.reduce((acc: ChatRoom[], curr) => {
      if (curr.room_name) {
        acc.push(curr)
      } else {
        const parent = curr.participants.find((item) => item.role === userTypes.parent)
        const userId = parent?.user_id

        const existingChatIndex = acc.findIndex((chat) => !chat.room_name && chat.participants.some((p) => p.role === userTypes.parent && p.user_id === userId))

        if (existingChatIndex === -1) {
          // Create new entry without grouped flag initially
          acc.push({
            ...curr,
            parent_name: parent?.user_name,
            rooms: [curr],
          })
        } else {
          // Add to existing group and set grouped=true since we found multiple
          acc[existingChatIndex] = {
            ...acc[existingChatIndex],
            grouped: true,
            rooms: [...(acc[existingChatIndex].rooms || []), curr],
          }
        }
      }
      return acc
    }, [])

    return grouped
  }, [tab, channels, searchTerm])

  /**
   * @Effect
   */
  useEffect(() => {
    return () => {
      setSelectedProfileId(undefined)
    }
  }, [])
  const { t } = useContentBundle({
    arabicContent: ar,
    englishContent: en,
    key: 'chat',
  })
  return {
    filteredChannels,
    user,
    tab,
    searchTerm,
    profileId: lastSelectedProfile,
    selectedProfileId,
    setSearchTerm,
    t,
    setTab,
    setSelectedProfileId,
    refetchChats,
  }
}
