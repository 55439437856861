import { Container } from 'components/Core'
import { CustomTabs } from 'components/shared/CustomTab/Tabs'
import { Conversions } from './Conversions'
import { ActiveUsers } from './ActiveUsers'
import { TherapistPerformance } from './TherapistPerformance'
import { CoursesList } from './CoursePerformance/CoursesList'

export const AdminAnalytics = () => {
  return (
    <Container justifyContent={['flex-start']} flexDirection={['column']} p={3} px={[0, 1, 3, 5]} height="100%" maxWidth="2024px">
      <CustomTabs
        tabs={[
          {
            label: 'Conversion',
            component: <Conversions />,
            value: 'conversion',
          },
          {
            label: 'Users',
            component: <ActiveUsers />,
            value: 'users',
          },

          {
            label: 'Therapists',
            component: <TherapistPerformance />,
            value: 'therapist-performance',
          },
          {
            label: 'Courses',
            component: <CoursesList />,
            value: 'courses-performance',
          },
        ]}
      />
    </Container>
  )
}
