import { Box, CenterColumn, Container, Flex, Text, Button, CenterRow, Chip } from 'components/Core'
import styled from 'styled-components'
import { useEnrollCourseMutation, useGetCourseDetailQuery, useGetCourseRatingsQuery } from './service'
import { motion } from 'framer-motion'
import { theme } from 'utils/theme'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Course, CourseJourneyItem } from 'utils/types/types'
import { Rating, Skeleton } from '@mui/material'
import { ceilingPrice, currencyFormatter } from 'utils/utils'
import { VideoPlayer } from './VideoPlayer'
import { Check } from '@mui/icons-material'
import { PaymentPage } from 'pages/PaymentPages'
import { useDisclosure } from 'hooks/useDisclosure'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { useAuth } from 'hooks/useAuth'
import { v4 } from 'uuid'
import { apiRoutes } from 'apiRoutes'
import { notify } from 'helpers'
import { successfulPaymentStatuses } from 'constants/index'
import { useCourseProgress } from './hook/useCourseProgress'
import { t } from 'i18next'
import { useCourseContent } from './hook/useCourseContent'

const JourneyCard = styled(motion.div)`
  background: ${theme.colors.white};
  padding: 20px;
  border-radius: 15px;
  // margin: 10px 0;
  box-shadow: ${theme.shadows.sm};
  border: 1px solid ${theme.colors.gray[200]};
`

type Props = {
  course: Course | undefined
  fetchingCourse: boolean
  courseId: string | number | undefined
  enroll?: any
  isSuccess?: boolean
  state?: any
  isEnrolling?: boolean
}
export const CourseDetail = ({ course, state, fetchingCourse, isSuccess, courseId, enroll, isEnrolling }: Props) => {
  const calculateDiscountedPrice = () => {
    if (!course?.price) return 0
    return course.price - (course.price * course.discount) / 100
  }
  const { open, onClose, onOpen } = useDisclosure()
  const { progress } = useCourseProgress(course?.journey)
  const { user } = useAuth()
  const orderDetails = useMemo(
    () => ({
      amount: ceilingPrice(calculateDiscountedPrice()),
      currency: 'USD',
      full_name: user.full_name,
      // phone_number: user.,
      email: user.email,
      description: 'Payment for course',
      order_id: course?.id,
      user_id: user.id,
      transaction_id: v4(),
      created_at: new Date().toISOString(),
    }),
    [user, course],
  )

  useEffect(() => {
    if (isSuccess) {
      notify('Enrolled successfully', false)
    }
  }, [isSuccess])
  const navigate = useNavigate()
  const { data: ratings, isLoading: fetchingRating } = useGetCourseRatingsQuery({
    course_id: courseId,
  })
  const { content, direction } = useCourseContent(course?.language)

  const actionButtonBg = useMemo(() => {
    if (progress && successfulPaymentStatuses.includes(course?.course_order?.status as string)) return '#4fade3'
    if (successfulPaymentStatuses.includes(course?.course_order?.status as string)) return '#5cb85c'
    return theme.colors.typePurpure['300']
  }, [progress, course?.course_order?.status])
  return (
    <>
      <Container direction={state?.direction ?? direction} bg="white" gap={16} py={[4]} pb={[5, 5, 4]} flexDirection={['column']} maxWidth="1400px" margin="auto">
        <Flex flexDirection={['column']} gap={16} px={[2, 3, 3, 4]} pb={[3]} minHeight={500} width="100%" style={{ background: theme.colors.linearGradient.lightBottomTop }}>
          <CenterRow width={['100%']} justifyContent={['space-between']}>
            {fetchingRating ? (
              <Skeleton variant="text" width={100} height={20} />
            ) : (
              <CenterRow gap={2}>
                <Rating value={ratings?.feedback_average} readOnly />
                <Text fontWeight="bold" color="warning" variant="light">
                  {ratings?.feedback_average} ({ratings?.feedbacks_count} {content.reviews})
                </Text>
              </CenterRow>
            )}
          </CenterRow>
          <Flex gap={16} flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'row']} justifyContent="space-around">
            <CenterColumn gap={16} height="100%" width={['100%', '100%', '100%', '50%']}>
              <CenterColumn>
                {fetchingCourse ? (
                  <CenterColumn>
                    <Skeleton variant="text" width={'90%'} height={35} />
                    <Skeleton variant="text" width={'60%'} height={35} />
                  </CenterColumn>
                ) : (
                  <Text width={['100%', '100%', '100%', '95%']} variant="heading2" color="primary" mb={4}>
                    {course?.title}
                  </Text>
                )}
                {fetchingCourse ? (
                  <>
                    <Skeleton variant="text" width={'90%'} />
                    <Skeleton variant="text" width={'90%'} />
                    <Skeleton variant="text" width={'90%'} />
                    <Skeleton variant="text" width={'90%'} />
                    <Box my={2} />
                    <Skeleton variant="text" width={300} />
                    <Skeleton variant="text" width={'90%'} />
                    <Skeleton variant="text" width={'90%'} />
                    <Skeleton variant="text" width={'90%'} />
                  </>
                ) : (
                  <Text width={['90%']} variant="body1" color="textSecondary" mb={4}>
                    {course?.short_description}
                  </Text>
                )}
              </CenterColumn>
              <CenterColumn gap={30}>
                {fetchingCourse ? (
                  <CenterRow flexWrap={['wrap']} gap={[8]}>
                    <Skeleton variant="rectangular" width={100} sx={{ borderRadius: '50px' }} height={35} />
                    <Skeleton variant="rectangular" width={100} sx={{ borderRadius: '50px' }} height={35} />
                    <Skeleton variant="rectangular" width={100} sx={{ borderRadius: '50px' }} height={35} />
                    <Skeleton variant="rectangular" width={100} sx={{ borderRadius: '50px' }} height={35} />
                  </CenterRow>
                ) : (
                  <Flex gap={[8]} flexWrap={['wrap']}>
                    <Chip type="default" value={`📚 ${course?.journey?.length ?? ''} ${content.lessons}`} />
                    {Array.from(new Set(course?.journey.map((item) => item.type))).map((type, index) => {
                      const count = course?.journey.filter((item) => item.type === type).length
                      return (
                        <Chip
                          type="default"
                          key={index}
                          value={`
                    ${type === 'video' ? '🎥' : ''}
                    ${type === 'document' ? '📄' : ''}
                    ${type === 'mcq' ? '❓' : ''} ${content?.[type]}
                    ${count ? `(${count})` : ''}
                    `}
                        />
                      )
                    })}
                  </Flex>
                )}
                <CenterColumn>
                  {fetchingCourse ? (
                    <Skeleton height={70} width={100} />
                  ) : (
                    <Button
                      // flexDirection="column"
                      position={['fixed', 'fixed', 'relative']}
                      width={['90%', '90%', 'fit-content']}
                      right={['0', '0', 'auto']}
                      left={['0', '0', 'auto']}
                      bottom={8}
                      mx={['auto', 'auto', '0px']}
                      borderRadius={['10px', '10px', '50px']}
                      onClick={() => {
                        if (course?.course_order) {
                          navigate(`/profile/parent/course/start/${courseId}`)
                          return
                        }

                        if (calculateDiscountedPrice() > 0) {
                          onOpen()
                        } else {
                          enroll?.({
                            course_id: courseId as string,
                          })
                        }
                      }}
                      boxShadow={['medium', 'medium', 'base', 'none']}
                      bg={actionButtonBg}
                    >
                      <>
                        {isEnrolling ? (
                          <Spinner width="25px" />
                        ) : course?.course_order ? (
                          progress ? (
                            content.continue
                          ) : (
                            content.startNow
                          )
                        ) : (
                          <CenterRow gap={1}>
                            <Text>{content.enrollNow}</Text>
                            {course?.price == 0 ? null : (
                              <>
                                {Number(course?.discount) > 0 && (
                                  <Text color={theme.colors.black[300]} textDecoration="line-through">
                                    {currencyFormatter({
                                      amount: course?.price,
                                    })}{' '}
                                  </Text>
                                )}
                                {Number(course?.discount) > 0 && <Text>/</Text>}
                                <Text fontWeight="bold">
                                  {currencyFormatter({
                                    amount: Number(course?.discount) > 0 ? ceilingPrice(calculateDiscountedPrice()) : course?.price,
                                  })}
                                </Text>
                              </>
                            )}
                          </CenterRow>
                        )}
                      </>
                    </Button>
                  )}
                </CenterColumn>
              </CenterColumn>
            </CenterColumn>
            {fetchingCourse ? (
              <Flex width={['100%', '100%', '100%', '50%']}>
                <Skeleton
                  variant="rectangular"
                  height="100%"
                  sx={{
                    p: 0,
                    m: 0,
                    alignItems: 'flex-start',
                    flexGrow: 1,
                    width: '100%',
                  }}
                />
              </Flex>
            ) : (
              <CenterColumn width={['100%', '100%', '100%', '50%']}>
                {course?.trailer_video && <VideoPlayer link={course?.trailer_video ?? ''} poster={course?.thumbnail ?? ''} />}
                {/* <video poster={course?.thumbnail ?? ''} src={course?.trailer_video ?? ''} controls style={{ width: '100%', height: 'auto', borderRadius: '10px' }} /> */}
              </CenterColumn>
            )}
          </Flex>
        </Flex>
        <Flex width={['100%']} p={[2, 2, 3, 4]} gap={8}>
          <Flex flexDirection={['column']} gap={16} width={['100%']}>
            <Text color="typePurpure.300" variant="body1" mb={4}>
              {content.overview}
            </Text>
            <AuthorCard>
              <Flex gap={24} flexDirection={['column', 'column', 'row']}>
                <Box>
                  <img
                    src={course?.author?.profile_pic || '/default-avatar.png'}
                    alt={course?.author?.full_name}
                    style={{
                      width: '120px',
                      height: '120px',
                      borderRadius: '60px',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
                <Flex flexDirection="column" gap={16} flex={1}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Box>
                      <Text variant="heading3" color="primary">
                        {course?.author?.full_name}
                      </Text>
                      <Text variant="body2" color="textSecondary">
                        {course?.author?.experience} {content.yearsOfExperience}
                      </Text>
                    </Box>
                  </Flex>

                  <Flex gap={8} flexWrap="wrap">
                    {course?.author?.specialties?.map((skill, index) => (
                      <Chip key={index} type="info" value={skill} />
                    ))}
                  </Flex>

                  <Text variant="body1" color="textSecondary">
                    {course?.author?.bio}
                  </Text>
                </Flex>
              </Flex>
            </AuthorCard>
            <CenterColumn gap={8}>
              <Text variant="heading3" mb={4}>
                {content.description}
              </Text>
              <Text
                maxWidth={['100%', '100%', '100%', '70%']}
                variant="body1"
                color="textSecondary"
                mb={6}
                dangerouslySetInnerHTML={{ __html: course?.long_description.replaceAll('\n', '<br/>') ?? '' }}
              />
            </CenterColumn>

            <CenterColumn gap={16}>
              <Text variant="heading3" mb={4}>
                {content.learningObjective}
              </Text>
              <CenterColumn gap={'8px'}>
                {course?.learning_objectives.map((objective, index) => (
                  <Flex key={index} gap={2} alignItems="center">
                    <Check />
                    <Text variant="body1" color="textSecondary">
                      {objective}
                    </Text>
                  </Flex>
                ))}
              </CenterColumn>
            </CenterColumn>
            <Text variant="heading3" mb={4}>
              {content.learningJourney}
            </Text>
            <CenterColumn gap={16}>
              {course?.journey.map((lesson, index) => (
                <JourneyCard key={index}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Box>
                      <Text variant="heading4" mb={2}>
                        {index + 1}. {lesson.title}
                      </Text>
                      <Flex gap={3}>
                        <Text variant="body2" color="textSecondary">
                          {lesson.type === 'video' && `🎥 ${content.videoLesson}`}
                          {lesson.type === 'document' && `📄 ${content.readingMaterial}`}
                          {lesson.type === 'mcq' && `❓ ${content.quiz}`}
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </JourneyCard>
              ))}
            </CenterColumn>
          </Flex>
        </Flex>
        {open && (
          <PaymentPage
            isOpen={open}
            closePopup={onClose}
            openPaymentExpiresPopup={() => {}}
            orderDetails={orderDetails}
            showTopLeftHeader={false}
            redirectUrl={`${process.env.REACT_APP_SITE_URL}/course-payment-success`}
            webhookUrl={apiRoutes.courses.create_course_webhookUrl}
          />
        )}
      </Container>
    </>
  )
}

const AuthorCard = styled(motion.div)`
  background: ${theme.colors.white};
  padding: 20px;
  border-radius: 15px;
  box-shadow: ${theme.shadows.sm};
  border: 1px solid ${theme.colors.gray[200]};
`
