// Core
import { useState } from 'react'
import { useSelector } from 'react-redux'
// Routing
import { NavLink } from 'react-router-dom'
// Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as EnvelopIcon } from '../../../theme/assets/icons/envelop-animated-icon.svg'
import { useAuth } from 'hooks/useAuth'
import { selectUnreadMessagesCount } from 'defaultLayout/slice/selectors'
import { Badge } from '@mui/material'

export const AdminNav = () => {
  const { user, userAuthenticated } = useAuth()
  const unreadMessagesCount = useSelector(selectUnreadMessagesCount)
  return (
    <div className={Styles.tabs_container}>
      <div className={Styles.tab_wrapper}>
        {(user?.user_type === 'advisor' || user?.user_type === 'admin') && (
          <NavLink to={`/admin_panel/${user?.user_type}/therapists?lng=en`} className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
            <span>{'Therapists'}</span>
          </NavLink>
        )}
      </div>
      {user?.user_type === 'advisor' && (
        <div className={Styles.tab_wrapper}>
          <NavLink to={`/admin_panel/${user?.user_type}/profiles?lng=en`} className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
            <span>{'Profiles'}</span>
          </NavLink>
        </div>
      )}
      {user?.user_type === 'admin' && (
        <div className={Styles.tab_wrapper}>
          <NavLink to={`/admin_panel/${user?.user_type}/parents`} className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
            <span>{'Clients'}</span>
          </NavLink>
        </div>
      )}
      {user?.user_type === 'admin' && (
        <div className={Styles.tab_wrapper}>
          <NavLink to={`/admin_panel/${user?.user_type}/advisors`} className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
            <span>{'Advisors'}</span>
          </NavLink>
        </div>
      )}
      {user?.user_type === 'admin' && (
        <div className={Styles.tab_wrapper}>
          <NavLink to={`/admin_panel/${user?.user_type}/meetings`} className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
            <span>{'Meetings'}</span>
          </NavLink>
        </div>
      )}
      {user?.user_type === 'admin' && (
        <div className={Styles.tab_wrapper}>
          <NavLink to={`/admin_panel/admin/analytics`} className={({ isActive }) => (isActive ? `${Styles.active} ${Styles.chat}` : `${Styles.tab} ${Styles.chat}`)}>
            <span>{'Analytics'}</span>
          </NavLink>
        </div>
      )}
      {user?.user_type === 'advisor' && (
        <div className={Styles.tab_wrapper}>
          <NavLink to={`/admin_panel/${user?.user_type}/applications?lng=en`} className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
            <span>{'Applications'}</span>
          </NavLink>
        </div>
      )}
      {user?.id && userAuthenticated && (
        <div className={Styles.tab_wrapper}>
          <Badge badgeContent={unreadMessagesCount ?? 0} color="secondary">
            <NavLink to={`/admin_panel/${user?.user_type}/chat`} className={({ isActive }) => (isActive ? `${Styles.active} ${Styles.chat}` : `${Styles.tab} ${Styles.chat}`)}>
              <span>{'Chats'}</span>
            </NavLink>
          </Badge>
        </div>
      )}
    </div>
  )
}
