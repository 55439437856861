//Core
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as BackIcon } from '../../../theme/assets/icons/arrow_left_icon.svg'
//Utels
import { getDate, getInitials, returnCountry, returnLanguagesFromArray } from '../../../utils'
//Actions
import { adminActions } from '../../../actions'
//Components
import { ProvideDiscountPopup } from '../Popups/ProvideDiscount'
import { HistoryRecord } from '../../ParentFlow/Therapy/HistoryRecord'
import { BooleanField } from '../../Core/BooleanField'
import { useAuth } from 'hooks/useAuth'

/**
 * Renders the ParentProfile component displaying detailed information about a parent's child.
 * @returns {JSX.Element} The rendered JSX element representing the ParentProfile component.
 */
export const ParentProfile = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { childId } = useParams()

  /**
   * Redux state selectors
   */
  // const user = useSelector((state) => state.auth.user)
  const { user: authUser } = useAuth()
  const user = { user: authUser }
  const child = useSelector((state) => state.admin.child)
  const history = useSelector((state) => state.admin.childRecords)

  /**
   * Component state
   */
  const [isOpenProvideDiscountPopup, setIsOpenProvideDiscountPopup] = useState(false)
  const [selectedTherapist, setSelectedTherapist] = useState({})
  const [selectedProfileId, setSelectedProfileId] = useState(undefined)

  const [refetch, setRefetch] = useState(false)

  /**
   * Fetches child profile and history data when `childId` or `refetch` changes.
   */
  useEffect(() => {
    const dataForProfile = {
      id: childId,
      token: user?.token,
    }

    const dataForHistory = {
      id: childId,
      token: user?.token,
    }

    dispatch(adminActions.getChildProfile(dataForProfile))
    dispatch(adminActions.getChildHistory(dataForHistory))
  }, [childId, refetch])

  /**
   * Navigates back to the previous page in history.
   */
  const handleGoBack = () => navigate(-1)

  /**
   * Opens the provide discount popup and sets selected therapist and profile ID.
   * @param {Object} therapist - The therapist object for which the discount is to be provided.
   * @param {string} profileId - The ID of the profile associated with the therapist.
   */
  const handleOpenDiscountPopup = (therapist, profileId) => {
    setSelectedTherapist(therapist)
    setSelectedProfileId(profileId)
    setIsOpenProvideDiscountPopup(true)
  }

  /**
   * Closes the provide discount popup and resets selected therapist.
   */
  const handleCloseDiscountPopup = () => {
    setSelectedTherapist({})
    setIsOpenProvideDiscountPopup(false)
  }

  /**
   * Renders the user profile picture or initials if no picture is available.
   * @param {Object} user - The user object containing profile information.
   * @param {boolean} isMatched - Indicates if the user is matched with a therapist.
   * @returns {JSX.Element} The rendered JSX element for the user profile.
   */
  const returnUser = (user, isMatched) => {
    return (
      <div style={{ width: isMatched && '60px', height: isMatched && '60px' }} className={Styles.user_container}>
        {user?.profile_pic ? <img src={user?.profile_pic} alt="user_pic" /> : getInitials(user?.full_name)}
      </div>
    )
  }

  /**
   * Renders therapist information including name, Hourly price, and discount details.
   * @param {Object} therapist - The therapist object containing information.
   * @param {boolean} isMatched - Indicates if the therapist is matched with the child.
   * @param {string} profileId - The ID of the profile associated with the therapist.
   * @returns {JSX.Element} The rendered JSX element for therapist information.
   */
  const returnTherapists = (therapist, isMatched, profileId) => {
    return (
      <div key={therapist.id} className={Styles.therapist_container}>
        <div className={Styles.therapist_container__content}>
          {returnUser(therapist, isMatched)}
          <div className={Styles.therapist_container__info}>
            <p>{therapist?.full_name}</p>
            {isMatched ? (
              therapist?.final_price ? (
                <div className={Styles.therapist_container__info__changed_price}>
                  <p>${Math.ceil(therapist?.final_price)}/h</p>
                  <span>${therapist?.session_price}/h</span>
                </div>
              ) : (
                <p className={Styles.therapist_container__info__orig_price}>{therapist?.discount_type === 'is_free' ? 'Free' : `$${therapist?.session_price}/h`}</p>
              )
            ) : (
              <span>${therapist?.session_price}/h</span>
            )}
            {isMatched && <span>Had sessions: {therapist?.sessions_count}</span>}
          </div>
        </div>
        {isMatched && (
          <div onClick={() => handleOpenDiscountPopup(therapist, profileId)} className={Styles.therapist_container__btn}>
            Discount
          </div>
        )}
      </div>
    )
  }

  /**
   * Navigates to the notes page for the child.
   */
  const handleOpenNotes = () => {
    navigate('notes')
  }

  return (
    <div className={Styles.main}>
      <div className={Styles.header} onClick={handleGoBack}>
        <BackIcon />
        <h6>Back to Clients</h6>
      </div>
      <div className={Styles.content}>
        <div className={Styles.profile_info}>
          <div className={Styles.child_header}>
            <div className={Styles.profile_info__child}>
              {returnUser(child?.profile)}
              <h6>{child?.profile?.full_name}</h6>
            </div>
            <div onClick={handleOpenNotes} className={Styles.violet_btn}>
              Check notes
            </div>
          </div>
          <div className={Styles.profile_info__info_block}>
            <div className={Styles.profile_info__info_block__two}>
              <div className={Styles.profile_info__info_block__item}>
                <span>Age</span>
                <p>{child?.profile?.age}</p>
              </div>
              <div className={Styles.profile_info__info_block__item}>
                <span>Birthday</span>
                <p>{child?.profile?.birth_date}</p>
              </div>
            </div>
            <div className={Styles.profile_info__info_block__two}>
              <div className={Styles.profile_info__info_block__item}>
                <span>Problem</span>
                <p>{child?.profile?.type === 'child' ? child?.concerns : child?.concerns?.join(', ')}</p>
              </div>
              <div className={Styles.profile_info__info_block__item}>
                <span>Had sessions</span>
                <p>{child?.sessions_count}</p>
              </div>
            </div>
          </div>
          <div className={Styles.profile_info__info_block}>
            <div className={Styles.profile_info__info_block__two}>
              <div className={Styles.profile_info__info_block__item}>
                <span>Client</span>
                <p>{child?.parent?.full_name}</p>
              </div>
              <div className={Styles.profile_info__info_block__item}>
                <span>Phone number</span>
                <p>{child?.parent?.phone_number}</p>
              </div>
            </div>
            <div className={Styles.profile_info__info_block__two}>
              <div className={Styles.profile_info__info_block__item}>
                <span>Country</span>
                <p>{returnCountry(child?.parent?.country)}</p>
              </div>
              <div className={Styles.profile_info__info_block__item}>
                <span>Language</span>
                <p>{child?.profile?.languages?.join(', ')}</p>
              </div>
            </div>
            <div className={Styles.profile_info__info_block__two}>
              <div className={Styles.profile_info__info_block__item}>
                <span>Email</span>
                <p>{child?.parent?.email}</p>
              </div>
            </div>
          </div>
          <div style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }} className={Styles.profile_info__therapists_block}>
            <div className={Styles.profile_info__therapists_block__item}>
              <div className={Styles.profile_info__therapists_block__item}>
                <span>Assigned therapists</span>
                <div className={Styles.profile_info__therapists_block__assigned}>
                  {child?.assigned_therapists?.length ? child?.assigned_therapists.map((therapist) => returnTherapists(therapist)) : <p>No assigned therapists</p>}
                </div>
              </div>
              <div className={Styles.profile_info__therapists_block__item}>
                <span>Current therapists</span>
                <div className={Styles.profile_info__therapists_block__matched}>
                  {child?.matched_therapists?.length ? (
                    child?.matched_therapists.map((therapist) => returnTherapists(therapist, true, child?.profile?.id))
                  ) : (
                    <p>No matched therapists</p>
                  )}
                </div>
              </div>
              <div className={Styles.profile_info__therapists_block__item}>
                <span>Is Email confirmed</span>
                <BooleanField value={child?.parent?.is_email_confirmed} />
              </div>
            </div>
            <div className={Styles.profile_info__therapists_block__item}>
              <div className={Styles.profile_info__therapists_block__item}>
                <span>Agree to terms and conditions</span>
                <BooleanField value={child?.parent?.is_agree_terms_conditions} />
              </div>
              <div className={Styles.profile_info__therapists_block__item}>
                <span>Agree to privacy policy</span>
                <BooleanField value={child?.parent?.is_agree_privacy_policy} />
              </div>
              <div className={Styles.profile_info__therapists_block__item}>
                <span>Agree to receive email promotion</span>
                <BooleanField value={child?.parent?.is_agree_to_receive_email} />
              </div>
            </div>
            <div className={Styles.profile_info__therapists_block__item}>
              <div className={Styles.profile_info__therapists_block__item}>
                <span>Preferred Language</span>
                <div className={Styles.profile_info__therapists_block__assigned}>{returnLanguagesFromArray(child?.parent?.preferred_language) ?? <p>Unknown</p>}</div>{' '}
              </div>
              <div className={Styles.profile_info__therapists_block__item}>
                <span>User created date</span>
                <p>{getDate(child?.parent?.created_at, true)}</p>
              </div>
              <div className={Styles.profile_info__therapists_block__item}>
                <span>user data updated at</span>
                <p>{getDate(child?.parent?.updated_at, true)}</p>
              </div>
            </div>
            <div className={Styles.profile_info__therapists_block__item}>
              <div className={Styles.profile_info__therapists_block__item}>
                <span>Promo code</span>
                <div className={Styles.profile_info__therapists_block__assigned}>{child?.parent?.promo_code}</div>
              </div>
              <div className={Styles.profile_info__therapists_block__item}>
                <span>Used promo code</span>
                <BooleanField value={child?.parent?.used_therapy_promo_code} />
              </div>
            </div>
          </div>

          <div className={Styles.profile_info__advisor_block}>
            {returnUser(child?.advisor?.user)}
            <div className={Styles.profile_info__advisor_block__info}>
              <p>{child?.advisor?.user?.full_name}</p>
              <span>Advisor</span>
            </div>
          </div>
        </div>
        <div className={Styles.records_info}>
          <div className={Styles.records_info__scroll}>
            <div className={Styles.records_info__header}>
              <h3>History</h3>
              <span>
                {history.length} {history.length === 1 ? 'meeting' : 'meetings'}
              </span>
            </div>
            {history?.map((record) => (
              <HistoryRecord record={record} key={record.id} userRole={'admin'} />
            ))}
          </div>
        </div>
      </div>
      {isOpenProvideDiscountPopup && (
        <ProvideDiscountPopup
          open={isOpenProvideDiscountPopup}
          onClose={handleCloseDiscountPopup}
          therapist={selectedTherapist}
          profileId={selectedProfileId}
          refetch={setRefetch}
        />
      )}
    </div>
  )
}
