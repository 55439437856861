import { Box, CenterColumn, Container, Flex, Text, CenterRow, Chip } from 'components/Core'
import styled from 'styled-components'
// import { useEnrollCourseMutation, useGetCourseDetailQuery, useGetCourseRatingsQuery } from ''
import { motion } from 'framer-motion'
import { theme } from 'utils/theme'
import { useEffect, useMemo } from 'react'
import { Course } from 'utils/types/types'
import { Avatar, LinearProgress, Rating } from '@mui/material'
import { Check } from '@mui/icons-material'
import { useDisclosure } from 'hooks/useDisclosure'
import { useAuth } from 'hooks/useAuth'
import { v4 } from 'uuid'
import { notify } from 'helpers'
import { useCourseProgress } from 'pages/Courses/hook/useCourseProgress'
import { useCourseContent } from 'pages/Courses/hook/useCourseContent'
import { useGetCourseRatingsQuery } from 'pages/Courses/service'

const JourneyCard = styled(motion.div)`
  background: ${theme.colors.white};
  padding: 20px;
  border-radius: 15px;
  // margin: 10px 0;
  box-shadow: ${theme.shadows.sm};
  border: 1px solid ${theme.colors.gray[200]};
`

type Props = {
  course: Course | undefined
  fetchingCourse: boolean
  courseId: string | number | undefined
  enroll?: any
  isSuccess?: boolean
  state?: any
  isEnrolling?: boolean
}
export const AdminCourseDetail = ({ course, state, fetchingCourse, isSuccess, courseId, enroll, isEnrolling }: Props) => {
  const calculateDiscountedPrice = () => {
    if (!course?.price) return 0
    return course.price - (course.price * course.discount) / 100
  }
  const { open, onClose, onOpen } = useDisclosure()
  const { getProgress } = useCourseProgress()
  const { user } = useAuth()
  const orderDetails = useMemo(
    () => ({
      amount: calculateDiscountedPrice(),
      currency: 'USD',
      full_name: user.full_name,
      // phone_number: user.,
      email: user.email,
      description: 'Payment for course',
      order_id: course?.id,
      user_id: user.id,
      transaction_id: v4(),
      created_at: new Date().toISOString(),
    }),
    [user, course],
  )

  useEffect(() => {
    if (isSuccess) {
      notify('Enrolled successfully', false)
    }
  }, [isSuccess])
  const { data: ratings, isLoading: fetchingRating } = useGetCourseRatingsQuery({
    course_id: courseId,
  })
  const { content, direction } = useCourseContent(course?.language)

  return (
    <>
      <Container direction={state?.direction ?? direction} bg="white" gap={16} py={[4]} pb={[5, 5, 4]} flexDirection={['column']} maxWidth="1400px" margin="auto">
        <Flex flexDirection={['column']} gap={16} px={[2, 3, 3, 4]} pb={[3]} width="100%">
          <CenterRow width={['100%']} justifyContent={['space-between']}>
            <CenterRow gap={2}>
              <Rating value={ratings?.feedback_average ?? 0} readOnly />
              <Text fontWeight="bold" color="warning" variant="light">
                {ratings?.feedback_average} ({ratings?.feedbacks_count} {content.reviews})
              </Text>
            </CenterRow>
          </CenterRow>
          <Flex gap={16} flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'row']} justifyContent="space-around">
            <CenterColumn gap={16} height="100%" width={['100%', '100%', '100%', '50%']}>
              <CenterColumn>
                <Text width={['100%', '100%', '100%', '95%']} variant="heading2" color="primary" mb={4}>
                  {course?.title}
                </Text>

                <Text width={['90%']} variant="body1" color="textSecondary" mb={4}>
                  {course?.short_description}
                </Text>
              </CenterColumn>
              <CenterColumn gap={30}>
                <Flex gap={[8]} flexWrap={['wrap']}>
                  <Chip type="default" value={`📚 ${course?.journey?.length ?? ''} ${content.lessons}`} />
                  {Array.from(new Set(course?.journey.map((item) => item.type))).map((type, index) => {
                    const count = course?.journey.filter((item) => item.type === type).length
                    return (
                      <Chip
                        type="default"
                        key={index}
                        value={`
                    ${type === 'video' ? '🎥' : ''}
                    ${type === 'document' ? '📄' : ''}
                    ${type === 'mcq' ? '❓' : ''} ${content?.[type]}
                    ${count ? `(${count})` : ''}
                    `}
                      />
                    )
                  })}
                </Flex>
              </CenterColumn>
            </CenterColumn>

            <CenterColumn width={['100%', '100%', '100%', '50%']}></CenterColumn>
          </Flex>
          <CenterColumn gap={30}>
            <CenterColumn mt={30} maxHeight={500} overflowY="scroll" gap={8}>
              <Text variant="heading3" color="primary" mb={4}>
                Progresses
              </Text>
              {course?.progresses.map((progress) => (
                <CenterColumn key={progress.id} border={1} p={16} borderRadius={10} gap={16}>
                  <CenterRow justifyContent="space-between" width={'100%'}>
                    <CenterRow gap={'4px'}>
                      <Avatar sizes="small" src={progress?.user?.profile_pic ?? 'https://placehold.co/400'} alt={progress?.user?.full_name} />
                      <Text variant="light">{progress.user.full_name}</Text>
                    </CenterRow>
                    {/* {console.log(progress.journey)} */}
                    <CenterColumn gap={'4px'} width="auto">
                      <CenterRow gap={'8px'}>
                        <Text variant="light" fontSize={[16]} maxWidth={['100%', '100%', '80%', '70%']}>
                          {getProgress(progress.journey) ?? 0}%
                        </Text>
                        <Text variant="light">Completed</Text>
                      </CenterRow>
                      <LinearProgress
                        color="primary"
                        sx={{
                          borderRadius: '20px',
                          height: '8px',
                          transform: direction === 'rtl' ? 'scaleX(-1)' : 'none',
                          minWidth: '120px',
                        }}
                        variant="determinate"
                        value={getProgress(progress.journey) ?? 0}
                      />
                    </CenterColumn>
                  </CenterRow>
                  {/* <Text variant="light" fontSize={[16]} maxWidth={['100%', '100%', '80%', '70%']}>
                  {progress.comment}
                </Text> */}
                </CenterColumn>
              ))}
            </CenterColumn>
            <CenterColumn maxHeight={500} overflowY="scroll" gap={8}>
              <Text variant="heading3" color="primary" mb={4}>
                Reviews
              </Text>
              {course?.feedbacks.map((feedback) => (
                <CenterColumn border={1} p={16} borderRadius={10} key={feedback.id} gap={16}>
                  <CenterRow justifyContent="space-between" width={'100%'}>
                    <CenterRow gap={'4px'}>
                      <Avatar sizes="small" src={feedback?.user?.profile_pic ?? 'https://placehold.co/400'} alt={feedback?.user?.full_name} />
                      <Text variant="light">{feedback.user.full_name}</Text>
                    </CenterRow>
                    <Rating value={feedback.rating} readOnly />
                  </CenterRow>
                  <Text variant="light" fontSize={[16]} maxWidth={['100%', '100%', '80%', '70%']}>
                    {feedback.comment}
                  </Text>
                </CenterColumn>
              ))}
            </CenterColumn>
          </CenterColumn>
        </Flex>
        <Flex width={['100%']} p={[2, 2, 3, 4]} gap={8}>
          <Flex flexDirection={['column']} gap={16} width={['100%']}>
            <Text color="typePurpure.300" variant="body1" mb={4}>
              {content.overview}
            </Text>
            <AuthorCard>
              <Flex gap={24} flexDirection={['column', 'column', 'row']}>
                <Box>
                  <img
                    src={course?.author?.profile_pic || '/default-avatar.png'}
                    alt={course?.author?.full_name}
                    style={{
                      width: '120px',
                      height: '120px',
                      borderRadius: '60px',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
                <Flex flexDirection="column" gap={16} flex={1}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Box>
                      <Text variant="heading3" color="primary">
                        {course?.author?.full_name}
                      </Text>
                      <Text variant="body2" color="textSecondary">
                        {course?.author?.experience} {content.yearsOfExperience}
                      </Text>
                    </Box>
                  </Flex>

                  <Flex gap={8} flexWrap="wrap">
                    {course?.author?.specialties?.map((skill, index) => (
                      <Chip key={index} type="info" value={skill} />
                    ))}
                  </Flex>

                  <Text variant="body1" color="textSecondary">
                    {course?.author?.bio}
                  </Text>
                </Flex>
              </Flex>
            </AuthorCard>
            <CenterColumn gap={8}>
              <Text variant="heading3" mb={4}>
                {content.description}
              </Text>
              <Text
                maxWidth={['100%', '100%', '100%', '70%']}
                variant="body1"
                color="textSecondary"
                mb={6}
                dangerouslySetInnerHTML={{ __html: course?.long_description.replaceAll('\n', '<br/>') ?? '' }}
              />
            </CenterColumn>

            <CenterColumn gap={16}>
              <Text variant="heading3" mb={4}>
                {content.learningObjective}
              </Text>
              <CenterColumn gap={'8px'}>
                {course?.learning_objectives.map((objective, index) => (
                  <Flex key={index} gap={2} alignItems="center">
                    <Check />
                    <Text variant="body1" color="textSecondary">
                      {objective}
                    </Text>
                  </Flex>
                ))}
              </CenterColumn>
            </CenterColumn>
            <Text variant="heading3" mb={4}>
              {content.learningJourney}
            </Text>
            <CenterColumn gap={16}>
              {course?.journey.map((lesson, index) => (
                <JourneyCard key={index}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Box>
                      <Text variant="heading4" mb={2}>
                        {index + 1}. {lesson.title}
                      </Text>
                      <Flex gap={3}>
                        <Text variant="body2" color="textSecondary">
                          {lesson.type === 'video' && `🎥 ${content.videoLesson}`}
                          {lesson.type === 'document' && `📄 ${content.readingMaterial}`}
                          {lesson.type === 'mcq' && `❓ ${content.quiz}`}
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </JourneyCard>
              ))}
            </CenterColumn>
          </Flex>
        </Flex>
      </Container>
    </>
  )
}

const AuthorCard = styled(motion.div)`
  background: ${theme.colors.white};
  padding: 20px;
  border-radius: 15px;
  box-shadow: ${theme.shadows.sm};
  border: 1px solid ${theme.colors.gray[200]};
`
