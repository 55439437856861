// Core
import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as UserIcon } from '../../../theme/assets/icons/user_updated.svg'
import { ReactComponent as TickIcon } from '../../../theme/assets/icons/check_updated.svg'
import { ReactComponent as IconDown } from '../../../theme/assets/icons/arrow_down_icon.svg'
import { ReactComponent as IconUp } from '../../../theme/assets/icons/arrow_up_icon.svg'
// Actions
import { parentActions } from '../../../actions'
import { setHistoryRecords } from '../../../reducers/parentSlice'
//MUI
import CircularProgress from '@mui/material/CircularProgress'
//Components
import { TherapistCard } from './TherapistCard'
import { TherapistInfoModal } from '../../AdvisorFlow/Therapists/TherapistsInfoModal'
import { CalComIntegration } from '../../GeneralComponents/CalCom'
import { Notifications, ConfirmationPopup, StepperModal } from '../../GeneralComponents'
import { HistoryRecord } from './HistoryRecord'
import AddProfilePopup from './AddProfilePopup'
import { MacOSPayment, PaymentPage } from '../../../pages/PaymentPages'
import { AcceptRecordingModal } from './AcceptRecordingModal'
//Tools
import WebSocketService from '../../../websocket'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'
import { translateLanguages, translateTherapistProfQualities, parentNotificationTranslations } from '../../../data/locale.data'

// Constants
import { CALCOM_ADVISOR_CHILD_LINK } from '../../../constants'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { selectAssignedTherapists } from './selectors'
import { selectFirebaseToken } from 'defaultLayout/slice/selectors'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { Box, Button, CenterColumn, Container, Flex, Text } from 'components/Core'
import { useAuth } from 'hooks/useAuth'
import { useQuestionnaireForm } from 'hooks/useQuestionnaireForm'
// Utils
// import { isSafariBrowser } from '../../../utils'

/**
 * ParentTherapyPage component for managing parent therapy session details.
 * @returns {JSX.Element} React component.
 */
export const ParentTherapyPage = () => {
  const dispatch = useDispatch()
  const firebaseToken = useSelector(selectFirebaseToken)

  const { t } = useTranslation()

  const navigate = useNavigate()

  /**
   * Redux state
   */
  // const user = useSelector((state) => state.auth.user)
  const { user } = useAuth()

  // const [language, setSelectedLanguage] = useState(localStorage.getItem('language'))

  // useEffect(() => {
  //   setSelectedLanguage(localStorage.getItem('language'))
  // }, [localStorage.getItem('language')])

  useEffect(() => {
    i18n.addResourceBundle('en', 'therapy_page', en)
    i18n.addResourceBundle('ar', 'therapy_page', ar)
  }, [])

  const {
    childInfo,
    currentTherapist: therapist,
    assignedAdvisor: advisor,
    acceptedTherapists,
    historyRecords = null,
    allProfiles,
    freeConsultation = null,
    profileId,
  } = useSelector((state) => state.parent)
  const [loading, setLoading] = useState(true)
  const assignedTherapists = useSelector(selectAssignedTherapists)

  const dropdownRef = useRef(null)

  /**
   * Component state
   */
  const [refetch, setRefetch] = useState(false)
  const [isOpenDetailsPopup, setIsOpenDetailsPopup] = useState(false)
  const [isOpenDiscoveryCallPopup, setIsOpenDiscoveryCallPopup] = useState(false)
  const [isOpenDeclinePopup, setIsOpenDeclinePopup] = useState(false)
  const [isOpenAddProfilePopup, setIsOpenAddProfilePopup] = useState(false)
  const [isOpenPaymentExpiresPopup, setIsOpenPaymentExpiresPopup] = useState(false)

  const [isAdultHasAccount, setIsAdultHasAccount] = useState(false)

  const [isRegularCall, setIsRegularCall] = useState(false)
  const [isStartToBookACall, setIsStartToBookACall] = useState(false)
  const [metadataForRecording, setMetadataForRecording] = useState(null)

  const [shouldShowNotifications, setShouldShowNotifications] = useState(false)
  const [notification, setNotification] = useState({})

  const [calComLink, setCalComLink] = useState('')
  const [selectedDiscoveryId, setSelectedDiscoveryId] = useState(0)
  const [selectedTherapistId, setSelectedTherapistId] = useState(0)
  const [selectedProfileId, setSelectedProfileId] = useState(0)
  const [isSelectedOption, setIsSelectedOption] = useState(false)
  const [selectedTherapistForRegularCall, setSelectedTherapistForRegularCall] = useState(0)

  const [history, setHistory] = useState(null)

  const [orderDetails, setOrderDetails] = useState({})
  const [isOpenPaymentPopup, setIsOpenPaymentPopup] = useState(false)
  const [isOpenMacOSPaymentPopup, setIsOpenMacOSPaymentPopup] = useState(false)

  const [webSocketInstance, setWebSocketInstance] = useState(null)
  const [isPaidCall, setIsPaidCall] = useState(false)

  //PAYMENT POPUP
  const handleOpenPaymentPopup = () => setIsOpenPaymentPopup(true)

  const handleClosePaymentPopup = () => setIsOpenPaymentPopup(false)

  /**
   * Effect to handle actions when selected profile changes or component mounts.
   * Handles setting local storage and dispatching actions.
   */
  useEffect(() => {
    if (selectedProfileId) localStorage.setItem('lastSelectedProfile', selectedProfileId)

    if (webSocketInstance) {
      dispatch(parentActions.getTherapyInfo(setLoading))
    }
  }, [refetch, selectedProfileId, webSocketInstance])

  /**
   * Effect to initialize WebSocket connection when user token is available.
   * Sets up WebSocket instance and manages connection state.
   */
  useEffect(() => {
    if (firebaseToken && user) {
      setWebSocketInstance(new WebSocketService('sessions', 0, user))
    }
  }, [JSON.stringify(user)])

  /**
   * Effect to manage WebSocket callbacks and session history fetch.
   * Handles connection, callbacks, and session history updates.
   */
  useEffect(() => {
    if (webSocketInstance && webSocketInstance.state() !== 1) {
      webSocketInstance.connect()

      const waitForSocketConnection = (callback) => {
        setTimeout(() => {
          if (webSocketInstance.state() === 1) {
            callback()
          } else {
            waitForSocketConnection(callback)
          }
        }, 100)
      }

      waitForSocketConnection(() => {
        webSocketInstance.setSessionsCallback(
          (data) => {
            dispatch(setHistoryRecords(data))
          },
          () => {
            setIsOpenDiscoveryCallPopup(false)
            setRefetch((prev) => !prev)
          },
        )
        webSocketInstance.setScreeningCallback((data) => {
          setIsOpenDiscoveryCallPopup(false)
          setOrderDetails(data?.order)
          if (data?.order?.status !== 'COMPLETED') {
            // if (isSafariBrowser()) {
            //   setIsOpenMacOSPaymentPopup(true)
            // } else {
            handleOpenPaymentPopup()
            // }
          }
        })
        if (profileId) {
          webSocketInstance.fetchFreeConsultation(profileId)
          webSocketInstance.fetchSessionsHistory(profileId)
        }
      })
    } else if (webSocketInstance) {
      webSocketInstance.setSessionsCallback(
        (data) => {
          dispatch(setHistoryRecords(data))
        },
        () => {
          setIsOpenDiscoveryCallPopup(false)
          setRefetch((prev) => !prev)
        },
      )

      webSocketInstance.setScreeningCallback((data) => {
        setIsOpenDiscoveryCallPopup(false)
        setOrderDetails(data?.order)
        if (data?.order?.status !== 'COMPLETED') {
          // if (isSafariBrowser()) {
          //   setIsOpenMacOSPaymentPopup(true)
          // } else {
          handleOpenPaymentPopup()
          // }
        }
      })

      if (webSocketInstance.state() === 1 && profileId) {
        webSocketInstance.fetchFreeConsultation(profileId)
        webSocketInstance.fetchSessionsHistory(profileId)
      }
    }
  }, [webSocketInstance, profileId])

  /**
   * Effect to reconnect WebSocket if connection state changes.
   * Handles reconnection when WebSocket state changes.
   */
  useEffect(() => {
    if (webSocketInstance && webSocketInstance?.state !== 1) {
      webSocketInstance.connect()
    }
  }, [webSocketInstance])

  /**
   * Effect to update session history when historyRecords or freeConsultation change.
   * Updates session history list when records or free consultation status change.
   */
  useEffect(() => {
    if (historyRecords && freeConsultation) {
      setHistory(
        [freeConsultation, ...historyRecords].sort((a, b) => {
          return new Date(b?.calcom_session?.start_time) - new Date(a?.calcom_session?.start_time)
        }),
      )
    }
  }, [historyRecords, freeConsultation])

  /**
   * Effect to check if an adult profile exists in allProfiles.
   * Checks for the presence of an adult profile and updates state.
   */
  useEffect(() => {
    const isAdultProfile = allProfiles?.find((profile) => profile?.profile?.type === 'adult')
    if (isAdultProfile) setIsAdultHasAccount(true)
  }, [allProfiles])

  /**
   * Effect to handle click outside dropdown to close it.
   * Adds event listener to document to close dropdown when clicked outside.
   */
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsSelectedOption(false)
      }
    }
    let timeoutId = null

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  /**
   * Toggles the profile selection dropdown.
   */
  const handleSelectedOption = () => setIsSelectedOption((prev) => !prev)

  /**
   * Opens the add profile popup.
   */
  const handleOpenAddProfilePopup = () => {
    setIsOpenAddProfilePopup(true)
    setIsRegularCall(false)
  }

  /**
   * Closes the add profile popup.
   */
  const handleCloseAddProfilePopup = () => setIsOpenAddProfilePopup(false)

  /**
   * Closes the therapist details popup.
   */
  const handleCloseDetailsPopup = () => setIsOpenDetailsPopup(false)

  /**
   * Closes the discovery call popup.
   */
  const handleCloseDiscoveryCallPopup = () => {
    setIsOpenDiscoveryCallPopup(false)
    setIsRegularCall(false)
    // window.location.reload()
  }

  /**
   * Closes the regular call popup.
   */
  const handleCloseRegularCallPopup = () => {
    setIsOpenDiscoveryCallPopup(false)
    setIsRegularCall(false)
  }

  /**
   * Closes the decline therapist popup.
   */
  const handleCloseDeclinePopup = () => {
    setIsOpenDeclinePopup(false)
  }

  /**
   * Opens the regular call popup for a therapist.
   * @param {string} link - The link for the regular call.
   */
  const handleOpenRegularCallPopup = (link, therapist_id) => {
    setSelectedTherapistForRegularCall(therapist_id)
    setCalComLink(link)
    setIsStartToBookACall(true)
    setIsRegularCall(true)
    setIsPaidCall(true)
  }

  /**
   * Handles accepting a therapist.
   * @param {number} id - The ID of the therapist.
   */
  const handleAcceptTherapist = (id) => {
    const data = {
      id: id,
    }

    const notificationObject = {
      title: 'The therapist was approved',
    }

    dispatch(parentActions.acceptTherapist(data)).then((res) => {
      setRefetch((prev) => !prev)
      setNotification(notificationObject)
      setShouldShowNotifications(true)
    })
  }

  /**
   * Handles declining a therapist.
   * @param {Object} data - Data object containing decline reason.
   */
  const handleDeclineTherapist = (data) => {
    const dataForDecline = {
      id: selectedDiscoveryId,
      notificationTranslations: parentNotificationTranslations?.dicline?.[language],
      payload: {
        decline_reason: data.reason,
      },
    }

    const notificationObject = {
      title: 'The therapist was declined',
      secondaryText: 'Now you can choose another therapist.',
    }

    dispatch(parentActions.declineTherapist(dataForDecline)).then((res) => {
      handleCloseDeclinePopup()
      setRefetch((prev) => !prev)
    })
  }

  const contentForDeclineTherapistPopup = {
    title: 'Are you sure you want to decline the therapist?',
    secondaryText: {
      text: 'What are the reasons for declining?',
      style: Styles.popup_text,
    },
    buttons: {
      direction: 'vertical',
      content: [
        {
          id: 1,
          text: 'Continue',
          style: Styles.btn_decline_application__delete,
          onClick: handleDeclineTherapist,
        },
        {
          id: 2,
          text: 'Cancel',
          style: Styles.btn_decline_application__cancel,
          onClick: handleCloseDeclinePopup,
        },
      ],
    },
  }

  /**
   * Opens the payment expiration popup.
   */
  const handleOpenPaymentExpiresPopup = () => setIsOpenPaymentExpiresPopup(true)

  /**
   * Closes the payment expiration popup.
   */
  const handleClosePaymentExpiresPopup = () => setIsOpenPaymentExpiresPopup(false)

  const contentForPaymentExpiresPopup = {
    title: 'The time to pay for the session has expired',
    secondaryText: {
      text: 'Please book and pay for the new session',
      style: Styles.popup_text,
    },
    buttons: {
      direction: 'vertical',
      content: [
        {
          id: 1,
          text: 'Continue',
          style: Styles.btn_decline_application__delete,
          onClick: handleClosePaymentExpiresPopup,
        },
      ],
    },
  }

  /**
   * Navigates to notes page for child.
   */
  const handleOpenNotes = () => {
    navigate(`${childInfo?.id}/notes`)
  }
  /**
   * Opens therapist details popup
   */
  const handleOpenDetailsPopup = (therapistId) => {
    setSelectedTherapistId(therapistId)
    setIsOpenDetailsPopup(true)
  }

  const handleBookAdvisorConsultation = () => {
    setCalComLink(CALCOM_ADVISOR_CHILD_LINK)
    setIsRegularCall(false)
    setIsStartToBookACall(true)
  }
  const { language, direction } = useGetLanguage()
  const { setOnboardingStep } = useQuestionnaireForm(true)
  const { pathname } = useLocation()
  const lastSelectedProfile = localStorage.getItem('lastSelectedProfile')
  useEffect(() => {
    setTimeout(() => {
      if (allProfiles?.length && !advisor && !loading && lastSelectedProfile) {
        navigate('/profile/parent/additional-info')
        setOnboardingStep('book_advisory_call')
      }
    }, 0)
  }, [allProfiles?.length, advisor, pathname, loading, lastSelectedProfile])
  return (
    <div className={Styles.main_container}>
      <>
        {shouldShowNotifications && (
          <Notifications icon={<TickIcon />} title={notification.title} secondaryText={notification.secondaryText || ''} onClose={() => setShouldShowNotifications(false)} />
        )}
        <div className={Styles.header}>
          {advisor ? <h4>{t('therapy_page:therapy')}</h4> : null}
          {advisor && (
            <div onClick={handleOpenAddProfilePopup} className={Styles.violet_btn_contained}>
              {t('therapy_page:add_profile')}
            </div>
          )}
        </div>
        {/* {childInfo ? ( */}
        <div className={Styles.content_container}>
          <div style={{ width: !advisor && '100%' }} className={`${Styles.content_container__info}`}>
            {advisor ? (
              <>
                <div className={Styles.content_container__info__main}>
                  <div className={Styles.content_container__info__header}>
                    <div className={Styles.child_header}>
                      <Flex flexWrap={['wrap']} className={Styles.content_container__info__personal}>
                        <Box p={2} width={[45, 45, 60]} height={[45, 45, 60]} className={Styles.content_container__info__personal__photo}>
                          <UserIcon
                            style={{
                              width: '100%',
                              height: '100%',
                            }}
                            className={Styles.icon}
                          />
                        </Box>
                        <h6 className={`${Styles.title_block} ClarityMask`} onClick={handleSelectedOption} ref={dropdownRef}>
                          {childInfo.full_name}
                          <div className={Styles.title_icon}>
                            <div className={Styles.title_handle}></div>
                            {isSelectedOption ? <IconUp className={Styles.title_icon} /> : <IconDown className={Styles.title_icon} />}
                            {isSelectedOption && allProfiles && (
                              <div className={Styles.profile_select}>
                                {allProfiles?.map((profile) => (
                                  <div onClick={() => setSelectedProfileId(profile.profile.id)} className={Styles.profile_select_item}>
                                    <div className={Styles.profile_select_icon}>
                                      <UserIcon />
                                    </div>
                                    {profile?.profile?.full_name ? <p>{profile?.profile?.full_name}</p> : <span>{t('therapy_page:child_info:selector_new_profile')}</span>}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </h6>
                      </Flex>

                      <div onClick={handleOpenNotes} className={Styles.violet_btn}>
                        {t('therapy_page:child_info:check_notes')}
                      </div>
                    </div>
                  </div>
                  <div className={Styles.content_container__info__child}>
                    <div className={Styles.content_container__info__child__item}>
                      <span>{t('therapy_page:child_info:child_information:birthday')}</span>
                      <p>{childInfo?.birth_date}</p>
                    </div>
                    <div className={Styles.content_container__info__child__item}>
                      <span>{t('therapy_page:child_info:child_information:age')}</span>
                      <p>
                        {childInfo?.age} {t('therapy_page:years')}
                      </p>
                    </div>
                    <div className={Styles.content_container__info__child__item}>
                      <span>{t('therapy_page:child_info:child_information:language')}</span>
                      {childInfo?.languages && <p>{childInfo?.languages?.map((item) => translateLanguages?.[item]?.[language] || item)?.join(', ')}</p>}
                    </div>
                  </div>
                </div>
                <div className={`${Styles.content_container__info__therapists_main} ClarityMask`}>
                  {advisor && <p>{t('therapy_page:care_team')}</p>}
                  <div
                    style={{
                      maxHeight: '350px',
                      overflow: 'scroll',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                    }}
                  >
                    <div className={Styles.content_container__info__therapists_main__container}>
                      {advisor && (
                        <div className={Styles.content_container__info__therapists_main__advisor}>
                          <div className={Styles.content_container__info__therapists_main__advisor__content}>
                            <div className={Styles.content_container__info__therapists_main__advisor__photo}>
                              {advisor?.profile_pic ? <img src={advisor?.profile_pic} /> : <UserIcon />}
                            </div>
                            <div className={Styles.content_container__info__therapists_main__advisor__content__info}>
                              <p className={Styles.advisor_name}>{advisor?.full_name}</p>
                              <span>{t('therapy_page:advisor')}</span>
                            </div>
                          </div>
                        </div>
                      )}
                      {acceptedTherapists?.length > 0 &&
                        acceptedTherapists?.map((therapist, index) => (
                          <Flex gap={16} flexWrap={['wrap']} key={acceptedTherapists?.id || index} className={Styles.content_container__info__therapists_main__advisor}>
                            <div className={Styles.content_container__info__therapists_main__advisor__content}>
                              <div className={Styles.content_container__info__therapists_main__advisor__photo}>
                                {therapist?.user?.profile_pic ? <img src={therapist?.user?.profile_pic} /> : <UserIcon />}
                              </div>
                              <div className={Styles.content_container__info__therapists_main__advisor__content__info}>
                                <p onClick={() => handleOpenDetailsPopup(therapist.id)} className={Styles.therapist_btn}>
                                  {therapist?.user?.full_name}
                                </p>
                                <span>{therapist?.professional_qualities?.map((value) => translateTherapistProfQualities?.[value]?.[language] || value)?.join(', ')}</span>
                              </div>
                            </div>
                            <Button flexGrow={[1, 1, 'initial']} onClick={() => handleOpenRegularCallPopup(therapist?.regular_link, therapist?.id)}>
                              {t('therapy_page:book_regular_call')}
                            </Button>
                          </Flex>
                        ))}
                    </div>
                  </div>
                </div>
                {assignedTherapists?.length > 0 && (
                  <div className={Styles.content_container__info__therapists_main}>
                    <div className={Styles.accepted_therapists_container}>
                      <p>{t('therapy_page:recommended_therapists')}</p>
                      <div className={`${Styles.accepted_therapists_container__therapists} ClarityMask`}>
                        {assignedTherapists?.length > 0 &&
                          assignedTherapists?.map((therapist) => (
                            <TherapistCard
                              key={therapist.id}
                              discovery={therapist}
                              actionForDetailsPopup={setIsOpenDetailsPopup}
                              openDiscoveryCallPopup={setIsStartToBookACall}
                              setCalComLink={setCalComLink}
                              setDeclinePopupOpen={setIsOpenDeclinePopup}
                              setDiscoveryId={setSelectedDiscoveryId}
                              acceptTherapist={handleAcceptTherapist}
                              setTherapistId={setSelectedTherapistId}
                              direction={direction}
                              language={language}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className={Styles.content_container__info__new_profile}>
                {allProfiles?.length > 1 && (
                  <div onClick={handleSelectedOption} className={Styles.content_container__info__new_profile__selector}>
                    <h6>{t('therapy_page:new_profile:chose_another_profile')}</h6>
                    {isSelectedOption ? <IconUp className={Styles.title_icon} /> : <IconDown className={Styles.title_icon} />}
                    {isSelectedOption && allProfiles && (
                      <div style={{ top: '35px', left: 0 }} className={Styles.profile_select}>
                        {allProfiles?.map((profile, index) => (
                          <div key={index} onClick={() => setSelectedProfileId(profile.profile.id)} className={Styles.profile_select_item}>
                            <div className={Styles.profile_select_icon}>
                              <UserIcon />
                            </div>
                            {profile?.profile?.full_name ? <p>{profile?.profile?.full_name}</p> : <span>{t('therapy_page:new_profile:new_profile')}</span>}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                {/* {allProfiles?.length ? (
                  <div className={Styles.content_container__info__new_profile__content}>
                    <h3>{t('therapy_page:new_profile:you_are_almost_here')}</h3>
                    <div onClick={handleBookAdvisorConsultation}>{t('therapy_page:new_profile:advisor_consultation')}</div>
                  </div>
                ) : loading ? ( */}
                {loading ? (
                  <Spinner />
                ) : (
                  <Container direction={direction} width={['100%', '100%', '100%', '70%', '50%']} px={[2]} gap={15} flexDirection="column">
                    <Text variant="heading2" color="typePurpure.300" textAlign="center">
                      {t('therapy_page:welcomeSection:title')}
                    </Text>
                    <Text fontWeight="normal" fontSize={[14, 16, 18]} lineHeight={1.2} textAlign="center">
                      {t('therapy_page:welcomeSection:description')}
                    </Text>
                    <Button
                      onClick={() => {
                        navigate('/profile/parent/additional-info')
                      }}
                      // width={[120]}
                    >
                      {t('therapy_page:welcomeSection:continue')}
                    </Button>
                  </Container>
                )}
              </div>
            )}
          </div>
          {advisor && (
            <div className={Styles.content_container__history}>
              <div className={Styles.content_container__history__header}>
                <div className={Styles.content_container__history__header__visits}>
                  <h2>{t('therapy_page:sessions')}</h2>
                  <span>
                    {history?.length} {history?.length === 1 ? `${t('therapy_page:visit')}` : `${t('therapy_page:visits')}`}
                  </span>
                </div>
              </div>
              <div style={{ height: '100%' }} className={Styles.records}>
                {history?.length ? (
                  history.map((record) => (
                    <HistoryRecord
                      clientName={record?.therapist?.full_name}
                      key={record?.id}
                      record={record}
                      userRole={'parent'}
                      openPaymentExpiresPopup={handleOpenPaymentExpiresPopup}
                    />
                  ))
                ) : history?.length === 0 ? (
                  <span>{t('therapy_page:no_history')}</span>
                ) : (
                  <CenterColumn height="100%" gap="5px" alignItems="center">
                    <Spinner />
                  </CenterColumn>
                )}
              </div>
            </div>
          )}
        </div>
        {/* ) : (
        <CircularProgress color="secondary" className={Styles.spiner} />
      )} */}
        {isOpenDetailsPopup && therapist && (
          <TherapistInfoModal open={isOpenDetailsPopup} onClose={handleCloseDetailsPopup} therapistId={selectedTherapistId} userType={'parent'} />
        )}
        {isOpenDiscoveryCallPopup && (
          <CalComIntegration
            open={isOpenDiscoveryCallPopup}
            onClose={isRegularCall ? handleCloseRegularCallPopup : handleCloseDiscoveryCallPopup}
            link={calComLink}
            userInfo={childInfo}
            isRegularCall={isRegularCall}
            metadata={metadataForRecording}
          />
        )}
        {isOpenDeclinePopup && (
          <ConfirmationPopup open={isOpenDeclinePopup} onClose={handleCloseDeclinePopup} content={contentForDeclineTherapistPopup} width={'436px'} isDeclinePopup={true} />
        )}
        {isOpenPaymentExpiresPopup && (
          <ConfirmationPopup open={isOpenPaymentExpiresPopup} onClose={handleClosePaymentExpiresPopup} content={contentForPaymentExpiresPopup} width={'436px'} />
        )}
        {isOpenAddProfilePopup && <AddProfilePopup open={isOpenAddProfilePopup} onClose={handleCloseAddProfilePopup} isAdultHasProfile={isAdultHasAccount} />}
        {isOpenPaymentPopup && orderDetails && (
          <PaymentPage isOpen={isOpenPaymentPopup} closePopup={handleClosePaymentPopup} orderDetails={orderDetails} openPaymentExpiresPopup={handleOpenPaymentExpiresPopup} />
        )}
        {isOpenMacOSPaymentPopup && (
          <MacOSPayment
            orderDetails={orderDetails}
            open={isOpenMacOSPaymentPopup}
            onClose={() => setIsOpenMacOSPaymentPopup(false)}
            openPaymentExpiresPopup={handleOpenPaymentExpiresPopup}
          />
        )}
        {isStartToBookACall && (
          <AcceptRecordingModal
            open={isStartToBookACall}
            onClose={() => setIsStartToBookACall(false)}
            openCalCom={setIsOpenDiscoveryCallPopup}
            setMetadata={setMetadataForRecording}
            therapist_id={selectedTherapistForRegularCall}
            isPaidCall={isPaidCall}
          />
        )}
      </>
    </div>
  )
}
