import Intercom from '@intercom/messenger-js-sdk'
import { useCallback, useEffect } from 'react'
import { authInstance } from 'utils/utils'
import { useAuth } from './useAuth'

export const useIntercom = (startOnMount = true) => {
  const { user } = useAuth()
  const firebaseUser = authInstance.currentUser
  const bootIntercom = useCallback(() => {
    if (user?.id) {
      Intercom({
        app_id: 'h2boatyn',
        user_id: String(user?.id), // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: user?.full_name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: user?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        created_at: Math.floor(new Date(String(firebaseUser?.metadata.creationTime)).getTime() / 1000), // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      })
    }
  }, [user])
  useEffect(() => {
    if (startOnMount) {
      bootIntercom()
    }
  }, [bootIntercom, startOnMount])

  return {
    bootIntercom,
  }
}
