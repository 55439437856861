//Core
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
//Actions
import { therapistActions } from '../../../actions/therapistActions'
import { setIsSelectedOpenZoomModal } from '../../../reducers/zoomSlice'
//Styles
import Styles from './styles.module.scss'
//Components
import { Table } from '../../Applies/Table'
import { Spinner } from '../../ChatSystem/Common/Spinner'
import { NoDataComponent, CustomPagination } from '../../GeneralComponents'
//MUI
import { InputBase, useMediaQuery } from '@mui/material'
//Icons
import { ReactComponent as SearchIcon } from '../../../theme/assets/icons/search_convex.svg'
//Utils
import { paginateArray } from '../../../utils'
import { useNavigate } from 'react-router-dom'
import { notify } from 'helpers'
import { useAlertController } from 'components/AlertMessage/useAlertController'
import { useTranslation } from 'react-i18next'
import { useDefaultLayoutSlice } from 'defaultLayout/slice'
import { selectAllPatients } from './selectors'
import { MyPatientsCardView } from '../Applications/MyPatientsCardView'
import { Box } from 'components/Core'

/**
 * Patients component for managing therapist patients.
 * @returns {JSX.Element} Patients component.
 */
export const Patients = () => {
  const { setZoomLeaveUrl, dispatch } = useDefaultLayoutSlice()

  /**
   * Redux state
   */
  const user = useSelector((state) => state.auth.user)
  const allPatients = useSelector(selectAllPatients)
  const loading = useSelector((state) => state.general.loading)

  /**
   * Component state
   */
  const [patients, setPatients] = useState([])

  const [currentPage, setCurrentPage] = useState(1)

  /**
   * Effect to fetch all patients on initial load and when user token changes.
   */
  useEffect(() => {
    const data = {
      token: user.token,
    }
    dispatch(therapistActions.getAllPatients(data))
  }, [user.token])

  /**
   * Effect to paginate patients when allPatients state changes or currentPage changes.
   */
  useEffect(() => {
    const paginatedData = paginateArray(allPatients)
    if (paginatedData.length) {
      setPatients([...(paginatedData[currentPage - 1] || 0)])
    } else {
      setPatients([])
    }
  }, [allPatients, currentPage])

  /**
   * Headers for the table
   */
  const headers = [
    { text: 'DATE CREATED', sorting: true },
    { text: 'NAME OF PROFILE', sorting: true },
    { text: 'PROBLEM', sorting: true },
    { text: 'BIRTHDAY', sorting: false },
    { text: 'MEETING TIME', sorting: true },
    { text: 'DETAILS', sorting: false },
  ]
  const navigate = useNavigate()
  const { setAlert } = useAlertController()
  const { t } = useTranslation()

  /**
   * Handles joining a Zoom meeting.
   * @param {string} calcomSessionId - The ID of the Calcom session for Zoom meeting.
   */
  const handleJoinMeeting = (calcomSessionId) => {
    if (calcomSessionId) {
      dispatch(setIsSelectedOpenZoomModal(calcomSessionId))
      setZoomLeaveUrl()
      navigate(`/session?id=${calcomSessionId}`)
    } else {
      notify(t('alertMessages:zoomMeetingNotFound'), true, false)
    }
  }

  /**
   * Click event handlers for various actions.
   */
  const onClickEvents = {
    joinMeeting: handleJoinMeeting,
  }

  /**
   * Handles input change for search.
   * @param {Object} e - The input change event.
   */
  const handleChangeInput = (e) => {
    const inputValue = e.target.value.toLowerCase()

    const filteredArray = allPatients.filter((patient) => patient.full_name.toLowerCase().includes(inputValue))
    setPatients(filteredArray)
  }
  const belowMd = useMediaQuery('(max-width: 992px)')
  return (
    <Box px={[3, 3, 20, 20, 40]} py={[24]} className={Styles.main_container}>
      <Box gap={10} flexWrap="wrap" className={Styles.header}>
        <h6>My patients</h6>
        <div className={Styles.header__search}>
          <div className={Styles.header__search__container}>
            <SearchIcon />
            <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search by name or email" onChange={(e) => handleChangeInput(e)} className={Styles.header__search__container__text} />
          </div>
        </div>
      </Box>
      {loading ? (
        <Spinner />
      ) : allPatients.length ? (
        <div className={Styles.data_container}>
          {belowMd ? (
            <MyPatientsCardView patients={patients} />
          ) : (
            <Table headers={headers} data={patients} userType={user?.user?.user_type} userDataType={'patient'} onClickEvents={onClickEvents} />
          )}
        </div>
      ) : (
        <NoDataComponent text={'There are no patients yet.'} />
      )}
      <CustomPagination dataArray={allPatients} currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </Box>
  )
}
