import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'
import type { Course, CourseTotals } from 'utils/types/types'

type FilterPayload = {
  start_date: string
  end_date: string
  page?: number
  page_size?: number
}
type ConversionsResponse = {
  advisory_calls: number | undefined
  discovery_calls: number | undefined
  new_signups: number | undefined
  paid_consultations: number | undefined
}

interface User {
  userId: number
  email: string
  full_name: string
  revenue: number
  session_count: number
  paid_sessions: number
  non_paid_session: number
}

export interface PaginatedUserResponse {
  count: number
  next: string | null
  previous: string | null
  totals: {
    total_revenue: number | undefined
    total_sessions: number | undefined
    paid_sessions: number | undefined
    non_paid_sessions: number | undefined
  }
  results: User[]
}

export type PaginatedResponse<T> = {
  count: number
  next: string | null
  previous: string | null
  results: T[]
}

export const adminAnalyticsService = rootServiceApi.injectEndpoints({
  endpoints: (builder) => ({
    getConversions: builder.query<ConversionsResponse, Partial<FilterPayload>>({
      query: ({ end_date, start_date }) => ({
        url: apiRoutes.adminAnalytics.conversions,
        method: 'GET',
        params: {
          start_date,
          end_date,
        },
      }),
    }),
    getActiveUsers: builder.query<PaginatedUserResponse, Partial<FilterPayload>>({
      query: (params) => ({
        url: apiRoutes.adminAnalytics.activeUsers,
        method: 'GET',
        params,
      }),
    }),
    getTherapistPerformance: builder.query<PaginatedUserResponse, Partial<FilterPayload>>({
      query: (params) => ({
        url: apiRoutes.adminAnalytics.therapistPerformance,
        method: 'GET',
        params,
      }),
    }),
    getAllCourses: builder.query<PaginatedResponse<Course>, Partial<FilterPayload>>({
      query: (params) => ({
        url: apiRoutes.adminAnalytics.courses.allCourses,
        method: 'GET',
        params,
      }),
    }),
    getCoursesTotal: builder.query<CourseTotals, Partial<FilterPayload>>({
      query: (params) => ({
        url: apiRoutes.adminAnalytics.courses.totals,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const { useGetConversionsQuery, useGetActiveUsersQuery, useGetTherapistPerformanceQuery, useGetAllCoursesQuery, useGetCoursesTotalQuery } = adminAnalyticsService
